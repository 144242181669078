/* Responsive Settings */
@media only screen and (max-width: 104.6875rem) {
    .timeline.item .clock {
        display: none;
    }
    .options .toggle .option {
        padding-left: 0.3125rem;
        padding-right: 0.3125rem;
        font-size: 0.875rem;
    }
}

@media only screen and (max-width: 81.875rem) {
    .fullSized {
        width: 100%;
        padding-left: 1.25rem;
        margin-left: auto;
        margin-right: auto;
        padding-right: 1.25rem;
    }
    .options .toggle .option {
        padding-left: 0.3125rem;
        padding-right: 0.3125rem;
        font-size: 0.75rem;
    }

    .tileSized {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0rem;
    }

    .midSized {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        min-width: 56.25rem;
    }
}

@media only screen and (max-width: 72.8125rem) {
    .cardStack .title,
    .fullModal .cardStack .title {
        font-size: 0.875rem;
    }
    #SubHeader .title {
        font-size: 1.875rem;
    }
    .icon.mobile {
        display: inline;
    }
    .gapped {
        grid-column-gap: 0.625rem;
    }
    .moveRight {
        height: 1.25rem;
        width: 1.25rem;
        font-size: 1.25rem;
        padding-left: 0.1875rem;
    }
    .moveLeft {
        height: 1.25rem;
        width: 1.25rem;
        font-size: 1.25rem;
        padding-left: 0.1875rem;
    }
}

@media only screen and (max-width: 53.125rem) {
    main {
        width: 100%;
        min-width: 12.5rem;
    }

    .cardStack .title,
    .fullModal .cardStack .title {
        font-size: 0.625rem;
    }
}

@media only screen and (max-width: 44.6875rem) {
    .label {
        font-size: 0.6875rem;
        margin-bottom: 0.125rem;
    }
    .area.mid {
        width: 100% !important;
        left: 0rem !important;
    }
    .note {
        font-size: 0.5625rem;
    }
    .button {
        min-width: 6.25rem;
    }
    .TitleLine {
        font-size: 1.125rem;
    }
    .buttonGrid {
        grid-template-columns: 6.25rem auto 6.25rem;
    }
    .midSized {
        width: 100%;
        margin-left: unset;
        margin-right: unset;
        padding-left: 4.375rem;
        padding-right: 0.625rem;
    }
    .selectedHeight {
        height: 12.5rem;
    }
    .fullModal input:read-only,
    .AwsButton {
        margin-bottom: unset;
    }
    .thirdGrid {
        grid-template-columns: 100%;
    }
    .fullModal .buttonBall.close {
        right: 0.625rem;
    }
    .fullModal .buttonBall.share {
        right: 3.125rem;
    }
    .tRow {
        margin-top: 0rem;
    }
    .buttonRow {
        padding-right: 0rem;
        padding-bottom: 0.9375rem;
        text-align: left;
        padding-bottom: 1.5625rem;
    }
    .grid.two {
        grid-template-columns: 100%;
    }
    .grid.three {
        grid-template-columns: 100%;
    }
    .rPad {
        padding-right: 0rem;
    }
    .lPad {
        padding-left: 0rem;
    }
    .half {
        width: 100%;
    }
    .listSized {
        width: 100%;
    }
    .grid.half {
        grid-template-columns: auto;
        grid-column-gap: 0.3125rem;
    }
    .fullModal {
      .buttonBall {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .fullModal .close {
        width: 2.125rem;
        height: 2.125rem;
        top: 0.625rem;
        right: 0.625rem;
        font-size: 0rem;

        .buttonLabel {
          display: none;
        }
    }
    .fullModal .buttonBall.support {
      top: 0.625rem;
      right: 3.275rem;
      width: 2.125rem;
      height: 2.125rem;
      .buttonLabel {
        display: none;
      }
    }
    .fullModal .back {
        width: 2.0625rem;
        height: 2.0625rem;
        top: 0.625rem;
        left: 0.625rem;
        font-size: 0rem;
    }
    .fullModal .share {
        width: 2.0625rem;
        height: 2.0625rem;
        top: 0.625rem;
        right: 3.125rem;
        font-size: 0rem;
    }
    .leftList {
        width: 100%;
        padding-left: 0rem;
        padding-right: 0rem;
    }
    .rightList {
        width: 100%;
        padding-left: 0rem;
        padding-right: 0rem;
        margin-top: 1.25rem;
    }
    .moveButtons {
        display: none;
    }
    #ShareModal {
        width: 100%;
    }
    #ShareModal .linkButton {
        display: none;
    }
    #Growler {
        right: 0.3125rem;
        left: 0.3125rem;
        bottom: 0.3125rem;
    }
    .beta {
        display: none;
    }
    .networks {
        width: 11.875rem;
    }
    .currentNetwork {
        padding-right: 1.4375rem;
    }
    .m90 {
        width: 90%;
    }
    .m70 {
        width: 70%;
    }
    .desktop {
        display: none;
    }
    .m15 {
        width: 15%;
    }
    .largeSized {
        padding-left: 0.625rem;
        padding-right: 0.625rem;
        width: 100%;
    }
    .fullSized {
        min-width: 50rem;
        width: 100%;
        margin-left: 0rem;
        padding-left: 0.625rem;
        margin-right: 0rem;
        min-width: 0.3125rem;
    }
    .fullModal .fullSized {
        padding-left: 0.625rem;
    }
    .fullSized.padded {
        padding-left: 0.625rem;
        padding-right: 0.625rem;
    }
    #SubHeader #Breadcrumb {
        font-size: 0.5rem;
    }
    #SubHeader .title {
        font-size: 0.75rem;
    }
    #SubHeader .title.action {
        font-size: 0.5rem;
        top: 0.625rem;
    }
    #NotificationsMenu.open {
        width: 100%;
    }
    #NotificationsMenu .growler {
        min-width: 0.625rem;
    }
    #NotificationsMenu #NotificationsList {
        -webkit-overflow-scrolling: touch;
    }
    div {
        -webkit-overflow-scrolling: touch;
    }
    .cardStack {
        margin-left: 0rem;
        width: 100%;
    }
    .sixGrid {
        grid-template-columns: 50% 50%;
        margin-right: 0.625rem;
    }
    .splitGrid {
        grid-template-columns: 100%;
    }
    main.table {
        padding-left: 0rem;
    }
    .third {
        margin-left: 0.625rem;
        width: 86%;
    }
    .twoThirds {
        margin-left: 0.625rem;
        width: 86%;
    }
    .TitleLine {
        grid-template-columns: auto;
    }
    #SubHeader .tab {
        margin-left: 0.3125rem;
        margin-right: 0.3125rem;
        font-size: 0.75rem;
    }
    .card {
        width: 100%;
        margin-left: 0rem;
    }
    .sized {
        width: 100%;
        padding-left: 4.375rem;
        padding-right: 0.625rem;
    }
    .fullModal .sized {
        padding-left: 0.625rem;
    }
    .sized.mid {
        width: 100%;
    }
    .thirtyOne3 {
        width: 86%;
        margin-left: 0rem;
    }
    #UsageChart {
        display: none;
    }
    #UsageList {
        width: unset;
        height: 19.375rem;
        top: 0.625rem;
        left: 0.625rem;
        right: 0.625rem;
    }
    #UsageTotal {
        position: absolute;
        bottom: -18.125rem;
        font-size: 0.875rem;
        text-align: center;
        width: 100%;
        line-height: 1.25rem;
        left: 0rem;
        right: 0rem;
        height: 1.25rem;
        top: unset;
        z-index: 30;
    }
    .pad20 {
        padding-left: 0rem;
        margin-left: 0rem;
    }
    .dateOptions {
        display: none;
    }
    .options {
        top: 3.125rem;
        width: 100%;
    }
    .noarea .subtitle {
        font-size: 0.75rem;
        margin-left: 0rem;
    }
    .noarea .icon {
        height: 9.375rem;
    }
    #AddUserModal {
        width: 100%;
    }
    .fullModal .formForm {
        width: 100%;
        padding-right: 0.625rem;
        padding-left: 0.625rem;
    }
    .fullModal .buttonLabel {
        line-height: 2rem;
        font-size: 0.6875rem;
    }
    .fullModal .subtitle {
        font-size: 1.375rem;
    }
    .noarea .title {
        font-size: 1rem;
    }
    .fullModal.inline {
        left: 0.9375rem;
        top: 0.9375rem;
        right: 0.9375rem;
    }
    .nameValueGrid.stacked .nameLabel {
        font-size: 0.5rem;
        line-height: 1.875rem;
    }

    .nameValueGrid.stacked .nameValue {
        font-size: 0.5rem;
    }
    .progressGrid {
        grid-template-columns: 3.125rem auto 3.125rem;
    }
    #OpenAlarmsArea {
        height: 23.125rem;
    }
    #OpenAlarmsArea.billing {
        height: 23.125rem;
    }

    .checkText {
        text-transform: uppercase;
        font-size: 0.625rem;
        font-weight: 600;
        color: var(--tableText);
        margin-left: -0.5625rem;
    }

    .icon.mobile {
        display: none;
    }
}
