.progressGrid {
    position: relative;
    float: left;
    clear: both;
    margin-bottom: 3.125rem;
    margin-top: 1.25rem;
    grid-column-gap: 0.625rem;
    width: 100%;
    display: grid;
    grid-template-columns: 6.25rem auto 6.25rem;
}

.progressMarker {
    width: 4.75rem;
    position: absolute;
    top: 2.8125rem;
}

.progressGrid .nameValueGrid {
    position: relative;
}

.markerLine {
    background-color: var(--stroke);
    width: 1px;
    height: 0.625rem;
    position: absolute;
    left: 50%;
    top: 0rem;
}

.progressArea {
    background-color: var(--stroke);
    border-radius: 0.4375rem;
    display: inline-block;
    height: 3.125rem;
}

.progressEncapsolate {
    position: relative;
    width: 100%;
    height: 100%;
    display: inline-block;
}

.progressBar {
    background-color: var(--green);
    border-radius: 0.4375rem;
    height: 100%;
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
    position: absolute;
    top: 0rem;
}

/* Upload Progress Styles */
.ProgressArea {
    width: 100%;
    height: 1.75rem;
    border-color: var(--primaryColor);
    border-width: 1px;
    border-style: solid;
    border-radius: 0.4375rem;
    background-color: var(--white);
    clear: both;
    position: relative;
}

.ProgressParent {
    padding: 0.625rem;
    position: relative;
}

.progress {
    width: 0rem;
    margin: 1px;
    background-color: var(--placeholder);
    position: absolute;
    left: 0.125rem;
    top: 0.125rem;
    bottom: 0.125rem;
    line-height: 1.75rem;
    padding-right: 0.375rem;
    background-clip: content-box;
    font-size: 0.75rem;
    line-height: 1.3125rem;
}
