/* Properties */
body,
:root {
    --navigation: #fff;
    --navigationOpaque: rgba(236, 238, 242, 0.85);
    --background: #f6f7fb !important;
    --table: #fff;
    --tableText: #434c5e !important;
    --lightText: #dfdfdf;
    --inputBorder: #d4d9e2;
    --logo: url(/assets/images/logo.png);
    --shadow: rgba(0, 0, 0, 0.07);
    --shadowCard: rgba(191, 210, 218, 1);
    --icon: #658292;
    --text: #97a0b2;
    --subText: #97a0b2;
    --hoverBackground: #ebeef3;
    --primaryColorOpaque: rgba(2, 115, 251, 0.3);
    --secondaryColorOpaque: rgba(8, 220, 90, 0.3);
    --primaryColor: #0273fb;
    --primary: #0273fb;
    --secondaryColor: #08dc5a;
    --secondary: #08dc5a;
    --opaque: rgba(0, 0, 0, 0);
    --logoLight: url(/assets/svgs/logo.svg);
    --logoDark: url(/assets/svgs/logo-dark.svg);
    --alpha: 0.5;
    --glass: rgba(255, 255, 255, 0.5);
    --infoBox: #e6f8ff;
    --infoBorder: #1abeff;
    --navigationTransparent0: rgba(255, 255, 255, 0);
    --navigationTransparent1: rgba(255, 255, 255, 1);
    --menu: #434c5e;
    --white: #fff;
    --offWhite: #e6eced;
    --offWhite_ALWAYS: #e6eced;
    --placeholder: #c6ccd7;
    --groupText: #78859f;
    --primaryColorHover: #022c77;
    --primaryColorShadow: rgba(0, 107, 255, 0.3);
    --primaryColorShadowLight: rgba(0, 107, 255, 0.08);
    --lightPrimary: #89c4e4;
    --black: #000;
    --yellow: #ffc000;
    --copiedRow: rgba(255, 192, 0, 0.3);
    --green: #00db48;
    --greenShadow: rgba(82, 255, 13, 0.3);
    --invalid: #ffdde5;
    --red: #ff0d49;
    --redHover: #d00034;
    --redShadow: rgba(255, 13, 73, 0.3);
    --stroke: #658292;
    --shadowThick: rgba(0, 0, 0, 0.4);
    --shadows: 0rem 0.5rem 0.5rem 0.125rem rgba(0, 0, 0, 0.08);
    --shadowsHover: 0rem 0.5rem 0.5rem 0.125rem rgba(0, 0, 0, 0.3);
    --shadowsLight: 0rem 0.75rem 1.4375rem 0rem rgba(191, 210, 218, 1);
    --shadowsLightHover: 0rem 0.75rem 1.4375rem 0rem rgba(191, 210, 218, 0.3);
    --shadowsLightActive: 0rem 0.75rem 1.4375rem 0rem rgba(191, 210, 218, 0);
    --boxShadow: rgba(0, 0, 0, 0.08);
    --haBadgeColor: var(--primaryColor);
    --transition: 0.3s;

    --appContainerHeight: calc( 100% - var(--headerHeight));

    --defaultBorderRadius: 0.4375rem;
    --buttonHeightSmall: 1.5625rem;
    --buttonImageSizeSmall: 1.25rem;
    --buttonImagePaddingSmall: 2.1875rem;
    --buttonImagePositionSmall: left calc(var(--paddingSmall) + 0.1875rem) center;
    --modalBorderRadius: 0.9375rem;
    --paddingSmall: 0.3125rem;
    --paddingMedium: 0.625rem;
    --paddingLarge: 0.625rem;
    --paddingXL: 0.9375rem;
    --paddingXXL: 1.25rem;
    --paddingXXXL: 1.575rem;
    --marginSmall: 0.3125rem;
    --marginMedium: 0.625rem;
    --marginLarge: 0.625rem;
    --marginXL: 0.9375rem;
    --marginXXL: 1.25rem;
    --gapSmall: 0.3125rem;
    --gapMedium: 0.625rem;
    --gapLarge: 0.625rem;
    --gapXL: 0.9375rem;
    --gapXXL: 1.25rem;
    --defaultInputHeight: 2.6875rem;
    --defaultLineHeight: 0.9375rem;
    --inputBorderRadius: 0.4375rem;
    --inputBorderWidth: 0.125rem;
    --spinnerSizeMedium: 1.25rem;
    --spinerBorderTopMedium: 0.125rem solid var(--stroke);
    --spinerBorderRightMedium: 0.125rem solid var(--stroke);
    --spinerBorderBottomMedium: 0.125rem solid transparent;
    --spinerBorderLeftMedium: 0.125rem solid transparent;

    --headerHeight: 4.0625rem;
    --sideBarMargin: 0rem;
    --navItemPadding: 0.9375rem;
    --navItemMargin: 0.625rem;
    --navItemBorderRadius: var(--defaultBorderRadius);
    --navContainerBorderWidth: 0.125rem;
    --navContainerBackground: var(--navigation);
    --navHeaderPadding: var(--paddingXXL);

    --sideNavigatorBoxShadow: none;
    --brightnessDark: .5;
    --appLoaderSize: 13rem;
}
