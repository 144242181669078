/* Charting Styles */
.options {
    position: absolute;
    right: 0.625rem;
    top: 0.4375rem;
    width: 75%;
    z-index: 15;
}

.fullModal .options {
    width: 100%;
}

.fullModal .options .toggle {
    float: left;
}

.options .toggle {
    background-color: var(--inputBorder);
    color: var(--tableText);
    border-radius: 0.4375rem;
    padding: 1px;
    width: unset;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.75rem;
    height: 1.875rem;
    position: relative;
    float: right;
    margin-left: 1.25rem;
    transition: 0.3s;
}

.options .toggle .option:hover {
    background-color: var(--inputBorder);
    filter: brightness(90%);
    transition: 0.3s;
}

.options .toggle .option:active {
    background-color: var(--inputBorder);
    filter: brightness(80%);
    transition: 0.3s;
}

.options .toggle .option {
    position: relative;
    float: left;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    border-radius: 0.4375rem;
    cursor: pointer;
    transition: 0.3s;
}

.options .toggle .option.selected {
    background-color: var(--primaryColor);
    color: var(--white);
    border-radius: 0.4375rem;
    cursor: inherit;
    box-shadow: 0rem 0.75rem 1.4375rem 0rem rgba(0, 0, 0, 0.4);
    transition: 0.3s;
}

.toggleButton {
    text-align: center;
    background-color: var(--inputBorder);
    color: var(--tableText);
    width: 2rem;
    height: 1.4375rem;
    line-height: 1.4375rem;
    font-size: 0.875rem;
    font-weight: 600;
    position: relative;
    float: left;
    cursor: pointer;
    border-radius: 0.25rem;
    margin-right: 0.3125rem;
    transition: 0.3s;
}

.toggleButton:hover {
    filter: brightness(90%);
    transition: 0.3s;
}

.toggleButton:active {
    filter: brightness(80%);
    transition: 0.3s;
}

.toggleButton.selected {
    color: var(--white);
    background-color: var(--primaryColor);
    box-shadow: 0rem 0.375rem 0.75rem 0rem rgba(0, 0, 0, 0.4);
    transition: 0.3s;
}

.dateOptions {
    position: absolute;
    text-align: right;
    top: 0.5rem;
    right: 0.5rem;
    width: 50%;
    font-size: 0.8125rem;
    line-height: 1.4375rem;
    z-index: 11;
}

.date {
    height: 1.4375rem;
    border-radius: 0.25rem;
    display: inline-block;
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
    cursor: pointer;
    border-style: solid;
    border-color: var(--inputBorder);
    border-width: 1px;
    background-color: var(--white);
}

.date:hover {
    color: var(--white);
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);
}

#UsageTotal {
    width: 10rem;
    height: 10rem;
    line-height: 10rem;
    background-color: var(--navigation);
    border-radius: 5rem;
    z-index: 10;
    font-size: 1.4375rem;
    text-align: center;
    position: absolute;
    left: 4.375rem;
    top: 4.6875rem;
}

#UsageChart {
    width: 40%;
    height: 19.375rem;
    margin: 0rem 1.25rem;
    position: absolute;
    padding-top: 0.3125rem;
}

#UsageList {
    right: 1.25rem;
    position: absolute;
    width: 50%;
    height: 19.375rem;
    top: 0rem;
}

#NetworkArea {
    padding-top: 1.875rem;
}

#UsageList .tRow {
    margin-top: 0rem;
    padding-bottom: 0.25rem;
}

#UsageList .bubble {
    margin-left: 0rem;
}

#UsageList .tHeadRow {
    box-sizing: border-box;
    background-color: var(--navigation);
    padding-left: 0.25rem;
    line-height: 3.4375rem;
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0;
    z-index: 10;
    transition: all 0.15s linear;
    border-bottom: unset;
}

#UsageList .tHead {
    position: relative;
    float: left;
    color: var(--tableText);
    font-size: 0.875rem;
    font-weight: 600;
    font-family: 'Open Sans';
    background-color: var(--primary);
    border-left: unset;
    height: 3.4375rem;
    text-overflow: ellipsis;
    transition: 0.5s;
    padding-left: unset;
    white-space: nowrap;
    border-bottom: solid 0.1875rem var(--primaryColor);
}

#UsageList .tCol {
    position: relative;
    float: left;
    font-size: 0.9375rem;
    font-weight: 400;
    font-family: 'Open Sans';
    text-overflow: ellipsis;
    height: 3.125rem;
    line-height: 3.125rem;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 0.4375rem;
}
