/* Loading Style Animation */
.loading {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 0;
}

.loading.reversed li {
    border: 0.125rem solid white;
    -webkit-animation: LOADINGREV 1s infinite;
    animation: LOADINGREV 1s infinite;
}

.loading.reversed li:nth-child(1n) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

.loading.reversed li:nth-child(2n) {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}

.loading.reversed li:nth-child(3n) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.loading li {
    height: 0;
    position: absolute;
    top: 50%;
    left: 0;
    width: 0;
    margin: 0;
    height: 0.75rem;
    width: 0.75rem;
    border: 0.125rem solid var(--primaryColor);
    border-radius: 100%;
    -webkit-transform: transformZ(0);
    transform: transformZ(0);
    -webkit-animation: LOADING 1s infinite;
    animation: LOADING 1s infinite;
}

.loading li:nth-child(1n) {
    left: -1.25rem;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

.loading li:nth-child(2n) {
    left: 0;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.loading li:nth-child(3n) {
    left: 1.25rem;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}
