/***
 ** Global Component Styling
***/

html {
    height: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

a {
    color: var(--primaryColor);
    text-decoration: none;
    transition: 0.5s ease;
}

a:hover {
    text-decoration: underline;
    color: var(--tableText);
}

div.hidden {
    display: none;
}

:focus {
    outline: 0;
}

body {
    margin: 0rem;
    background-color: var(--background);
    color: var(--tableText);
    font-weight: 400;
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 0.875rem;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto !important;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

div {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    background-repeat: no-repeat;
}

.primary {
    color: var(--primaryColor);
}

.secondary {
    color: var(--secondaryColor);
}

.hidden {
    display: none !important;
}

.menu {
    background-color: var(--menu);
    border-radius: 0.4375rem;
    box-shadow: 0 0 0 1px var(--shadow), 0 0.5rem 1rem var(--shadow);
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--white);
    letter-spacing: 1px;
    height: 0rem;
    opacity: 0;
    transition: 0.2s;
    z-index: 50;
    padding-bottom: 0.625rem;
    padding-top: 0.625rem;
}

.menu.open {
    height: unset;
    opacity: 1;
}

.menu .link {
    cursor: pointer;
    line-height: 2.25rem;
    white-space: nowrap;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    transition: 0.2s;
}

.menu .link:hover {
    background-color: var(--primaryColor);
}

.menu .menutitle {
    opacity: 0.8;
    white-space: nowrap;
    line-height: 2.25rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    color: var(--text);
}

.hover {
    white-space: nowrap;
    position: absolute;
    pointer-events: none;
    left: 3.375rem;
    top: 0.4375rem;
    background-color: var(--menu);
    padding: 0.625rem;
    border-radius: 0.25rem;
    box-shadow: var(--shadows);
    font-size: 0.875rem;
    color: var(--white);
    letter-spacing: 1px;
    height: 0rem;
    opacity: 0;
    font-family: Open Sans;
    font-weight: 600;
    transition: 0.2s;
}

.hover.open {
    height: unset;
    opacity: 1;
}

.mopView {
    .mt8 {
        .top-cell {
            display: none;
        }
    }
    temp.overviewpod {
        .col-md-2 {
            width: 35%;
        }
    }
}

.side-navigator-menu-group {
    .getting-started-nav {
        display: none;
    }
}

.side-navigator-menu-item-icon .icon-time:before {
    content: '\e96b';
}

@media (max-width: 64rem) {
    main {
        &.zacView {
            left: 0 !important;
            &.open {
                left: 0rem !important;
            }
        }
    }
}

#ConfirmModalZitiConnectionFailed {
  height: 16.25rem;
  min-height: 16.25rem;
  .title {
    margin-top: 0.9375rem;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: 0;
    padding: 0;
  }
  .subtitle {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    width: 100%;
    padding: 0;
    padding-left: 2.5rem;
    padding-right: 1.25rem;
    padding-bottom: 1.25rem;
    color: var(--tableText);
    font-size: 0.875rem;
    padding-left: 10rem;
  }
  .icon {
    width: 8.125rem;
    height: 8.125rem;
  }
  .buttons {
    padding: 0 1.875rem;
    .linkButton {
      margin-top: 0;
      display: flex;
      align-items: flex-end;
    }
    .confirm {
      position: relative;
      right: 0;
      bottom: 0.3125rem;
    }
  }
  .close {
    &.cancel {
      top: 0.9375rem;
    }
  }
}

.tab[data-go="/recipes"] {
  display: none;
}

#DetailModal,
#AddMessageModal,
#QRModal,
#ResetModal,
#OverrideModal,
#AddModal {
  &.open {
    &.box {
      &.full {
        width: calc(100% - 19.6875rem)!important;
        height: calc(100% - 4.0625rem) !important;
        left: 19.6875rem!important;
        top: 4.0625rem !important;
      }
    }
  }
}

.ag-cell-value {
  >span {
    display: flex;
    align-items: center;
    height: 100%;

    .pi {
      top: unset;
      &.pi-check-circle {
        color: var(--green);
      }
      &.pi-ban {
        color: var(--red);
      }
    }
    .ha-leader {
      align-items: center;
    }
  }
}


.modal.solid.open .buttons {
  margin-bottom: var(--marginMedium);
}

app-list-header {
  z-index: 10001;
}
