/* Form Element Styling */

input,
textarea,
select {
    font-weight: 600;
    font-family: 'Open Sans', Arial, sans-serif;
    height: 3.4375rem;
    width: 100%;
    border-color: var(--inputBorder);
    border-style: solid;
    border-width: 0.125rem;
    border-radius: 0.4375rem;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    outline: none;
    box-sizing: border-box;
    font-size: 0.9375rem;
    background-color: var(--navigation) !important;
    color: var(--tableText);
    -webkit-appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    background-color: var(--navigation) !important;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: var(--tableText) !important;
}

select {
    background-image: url(/assets/svgs/ArrowDown.svg);
    background-position: center right 0.625rem;
    background-repeat: no-repeat;
    background-size: 1.125rem;
    padding-right: 1.75rem;
}

select:focus {
    background-image: url(/assets/svgs/ArrowUp.svg) !important;
    background-position: center right 0.625rem;
    background-repeat: no-repeat;
    background-size: 1.125rem;
    padding-right: 1.75rem;
}

input:focus,
textarea:focus,
select:focus {
    border-color: var(--primaryColor);
    box-shadow: 0 0rem 0.4375rem 0 var(--primaryColorOpaque);
}

input.error,
textarea.error,
select.error {
    border-color: var(--red);
    box-shadow: 0 0rem 0.4375rem 0 var(--redShadow);
    -webkit-transition: background-color 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

input.curved {
    border-radius: 1.5625rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    background-origin: padding-box;
}

.searchArea {
    transform: translate(0rem, 2.8125rem);
}

textarea {
    height: 9.375rem;
    resize: none;
    margin-top: -1.25rem;
    padding-top: 0.625rem;
}

label.error {
    color: var(--red) !important;
}

span.error {
    color: var(--red);
    position: absolute;
    right: 0rem;
    bottom: 0rem;
}

select:invalid {
    color: var(--placeholder);
}

#ccCard {
    border-color: var(--inputBorder);
    border-style: solid;
    border-width: 0.125rem;
    border-radius: 0.4375rem;
    padding-left: 0.625rem;
    clear: both;
    background-color: var(--white);
}

.fullModal .select2-container--default.select2-container--focus .select2-selection--multiple,
.roleForm .select2-container--default.select2-container--focus .select2-selection--multiple,
.fullWidth .select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: var(--border);
}

.fullModal .select2-container--default .select2-selection--multiple .select2-selection__rendered,
.fullWidth .select2-container--default .select2-selection--multiple .select2-selection__rendered,
.roleForm .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    margin-bottom: -0.1875rem;
    border-color: var(--primaryColor);
    background-color: var(--navigation);
}

.fullModal
    .select2-container--default:focus-within
    .select2-selection--multiple:focus-within
    .select2-selection__rendered:focus-within,
.fullWidth
    .select2-container--default:focus-within
    .select2-selection--multiple:focus-within
    .select2-selection__rendered:focus-within,
.roleForm
    .select2-container--default:focus-within
    .select2-selection--multiple:focus-within
    .select2-selection__rendered:focus-within {
    border-color: var(--primaryColor);
    box-shadow: 0 0rem 0.4375rem 0 var(--primaryColorOpaque);
}

.fullModal .select2-container--default .select2-selection--multiple .select2-selection__choice__remove,
.fullWidth .select2-container--default .select2-selection--multiple .select2-selection__choice__remove,
.roleForm .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    position: absolute;
    left: 0.625rem;
    font-size: 2rem;
    cursor: pointer;
    display: inline-block;
    font-weight: normal;
    margin-right: 0.125rem;
    color: var(--white);
}

.fullModal .select2-container--default .select2-selection--multiple,
.fullWidth .select2-container--default .select2-selection--multiple,
.roleForm .select2-container--default .select2-selection--multiple {
    background-color: unset;
}

.fullModal .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover,
.fullWidth .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover,
.roleForm .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: var(--primaryColor);
}

.select2-container .select2-dropdown {
    background-color: var(--navigation);
}

.select2-container.select2-container--default .select2-results__option[aria-selected='true'] {
    background-color: var(--primaryColor);
    color: var (--white);
    font-size: 1rem;
}

.fullModal .select2-container--default .select2-search--inline .select2-search__field,
.fullWidth .select2-container--default .select2-search--inline .select2-search__field,
.roleForm .select2-container--default .select2-search--inline .select2-search__field {
    height: 2.75rem;
}

.fullModal .select2-container--default .select2-selection--multiple .select2-selection__choice,
.fullWidth .select2-container--default .select2-selection--multiple .select2-selection__choice,
.roleForm .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: var(--primaryColor);
    color: #fff;
    line-height: 2.125rem;
    font-weight: 600;
    font-size: 1rem;
    padding-right: 0.625rem;
    padding-left: 2rem;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    border: none;
    border-radius: 0.4375rem;
    cursor: default;
    float: left;
    margin-left: 0.4375rem;
    margin-top: 0rem;
    margin-bottom: 0.5rem;
    position: relative;
}

.fullModal .select2-container--default .select2-search--inline .select2-search__field,
.fullWidth .select2-container--default .select2-search--inline .select2-search__field,
.roleForm .select2-container--default .select2-search--inline .select2-search__field {
    height: 2.75rem;
}

.select2-search__field input {
    height: 3.125rem !important;
}

.wizard {
    color: var(--text);
    padding: 0.9375rem;
    background-color: var(--navigation);
    box-shadow: 0rem 1.25rem 1.4375rem 0rem var(--shadow);
    position: relative;
    clear: both;
    border-radius: 0.4375rem;
    margin-top: 1.25rem;
}

.wizard.noShadow {
    padding: 0.9375rem;
    position: relative;
    clear: both;
    border-radius: 0.4375rem;
    margin-top: 1.25rem;
    box-shadow: none;
    background-color: inherit;
}

.fullModal .wizard .icon {
    position: absolute;
    right: 0.25rem;
    height: 100%;
    background-position: center center;
    background-size: 7.8125rem;
    min-width: unset;
    min-height: unset;
    max-width: unset;
    background-image: unset;
    float: unset;
    top: 0rem;
    left: unset;
    opacity: 0.4;
}

.wizard .summary {
    position: relative;
    float: none;
    clear: both;
    padding-left: 2.8125rem;
    padding-right: 0.5rem;
    padding-top: 0.625rem;
}

.wizard .summary .step {
    left: 0rem;
    top: 0rem;
}

.fullModal .wizard .summary label {
    width: 100%;
}

.wizard .summary .grid {
    width: 100%;
}

.fullModal .wizard .summary .title {
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    padding-left: 0rem;
    padding-top: 0.625rem;
}

.fullModal .wizard .summary .subtitle {
    padding-left: 0rem;
    margin-top: -0.3125rem;
    font-size: 0.8125rem;
    text-align: left;
    font-weight: 600;
}

.wizard .icon.step5.upside {
    top: 0.625rem;
}

.underline {
    border-bottom-color: var(--primaryColor);
    border-bottom-style: solid;
    border-bottom-width: 0.125rem;
}

.wizard .step {
    background-color: var(--inputBorder);
    color: var(--tableText);
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    text-align: center;
    line-height: 2rem;
    font-size: 1rem;
    font-weight: 600;
    position: absolute;
    left: 0.9375rem;
    top: 0.9375rem;
    margin-top: 0.25rem;
}

.wizard .row {
    float: none;
}

.wizard .linkButton {
    padding-left: unset;
    line-height: 2.1875rem;
    font-weight: bold;
    color: var(--primaryColor);
    text-transform: uppercase;
}

.fullModal .wizard .title {
    padding-top: unset;
    text-align: left;
    width: 100%;
    color: var(--tableText);
    font-weight: 300;
    font-family: 'Open Sans';
    text-transform: none;
    position: relative;
    font-size: 1.375rem;
    line-height: 2rem;
    padding-left: 2.8125rem;
}

.fullModal .wizard .title.andor {
    padding-top: unset;
    text-align: left;
    width: 100%;
    color: var(--tableText);
    font-weight: 300;
    font-family: 'Open Sans';
    text-transform: none;
    position: relative;
    font-size: 1.375rem;
    line-height: 2rem;
    padding-left: 0rem;
}

.fullModal .hint {
    background-color: var(--navigation);
    border-radius: 0.4375rem;
    width: 18.125rem;
    box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadow);
    background-image: url(/assets/svgs/Hint.svg);
    background-size: 3.375rem;
    position: relative;
    background-position: -0.1875rem;
    background-origin: padding-box;
    height: 4.0625rem;
}

.fullModal .hint .title {
    padding-top: 0.5rem;
    left: 2.6875rem;
    position: relative;
    text-align: left;
    width: 100%;
    color: var(--tableText);
    font-family: 'Open Sans';
    position: relative;
    font-size: 1.375rem;
    font-weight: 400;
}

.fullModal .hint .subtitle {
    left: 2.8125rem;
    margin-top: -0.125rem;
    position: relative;
    text-align: left;
    width: 100%;
    color: var(--tableText);
    font-family: 'Open Sans';
    position: relative;
    font-size: 0.875rem;
    font-weight: 400;
}

.fullModal .hint .image {
    position: relative;
    left: 15rem;
    top: -2.5rem;
    height: 100%;
    background-position: center center;
    background-size: contain;
    font-size: 2.3125rem;
    color: var(--text);
}

.wizard .icon.step1 {
    background-image: url(/assets/svgs/AppName.svg);
}

.wizard .icon.step2 {
    background-image: url(/assets/svgs/Hosted.svg);
}

.wizard .icon.step3 {
    background-image: url(/assets/svgs/IP.svg);
}

.wizard .icon.step4 {
    background-image: url(/assets/svgs/PeoplePlaces.svg);
}

.wizard .icon.step5 {
    background-image: url(/assets/svgs/No_ServicesConnections.svg);
    top: 1.5625rem;
    height: 4.6875rem;
    background-size: 65%;
}

.wizard .grid,
.wizard .grid.three,
.wizard .grid.two {
    width: 75%;
    grid-column-gap: 0.1875rem;
}

.wizard .grid.three {
    grid-template-columns: 4.6875rem auto 8.75rem;
}

.wizard .grid input,
.wizard .grid select,
.wizard .grid label {
    width: 100%;
}

.wizard input,
.wizard select {
    width: 75%;
}

.wizard .gridAdd:before {
    content: '\e937';
}

.wizard .gridAdd.minus:before {
    content: '\e943';
}

.wizard .gridAdd.minus {
    background-color: var(--red);
    -webkit-box-shadow: 0 0.1875rem 0.5625rem 0 var(--s);
    box-shadow: 0 0.1875rem 0.5625rem 0 var(--redShadow);
}

.wizard .gridAdd.minus:hover {
    color: var(--white);
    background-color: var(--red);
    filter: brightness(90%);
    transition: background-color 1s;
    transition: 0.3s;
}

.wizard .gridAdd.minus:active {
    background-color: var(--icon);
    transform: scale(0.97);
    transition: background-color 1s;
    transition: 0.3s;
    box-shadow: none;
}

.wizard .gridAdd {
    width: 2.1875rem;
    height: 2.1875rem;
    position: absolute;
    left: 77%;
    top: 0.5rem;
    color: var(--white);
    border-radius: 2.1875rem;
    background-color: var(--primaryColor);
    -webkit-box-shadow: 0 0.1875rem 0.5625rem 0 var(--primaryColorOpaque);
    box-shadow: 0 0.1875rem 0.5625rem 0 var(--primaryColorOpaque);
    -webkit-transition: background-color 0.5s;
    transition: background-color 0.5s;
    font-family: 'icomoon' !important;
    font-size: 1.4375rem;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 2.125rem;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
    z-index: 1000;
}

.wizard .gridAdd:hover {
    color: var(--white);
    background-color: var(--primaryColor);
    filter: brightness(90%);
    transition: background-color 1s;
    transition: 0.3s;
}

.wizard .gridAdd:active {
    background-color: var(--icon);
    transform: scale(0.97);
    transition: background-color 1s;
    transition: 0.3s;
    box-shadow: none;
}

.wizard label.thin {
    color: var(--tableText);
    font-weight: 400;
    font-family: 'Open Sans';
    font-size: 1.125rem;
    text-transform: none;
}

.wizard label {
    color: var(--tableText);
    text-transform: uppercase;
    margin-bottom: 0.3125rem;
    margin-top: 0.625rem;
    font-size: 0.8125rem;
    font-weight: 600;
    font-family: 'Open Sans';
    position: relative;
    float: left;
    width: 75%;
    clear: both;
}

.button.hidden {
    display: none;
}

.toggleProvider:first-child {
    margin-right: 4%;
}

.toggleProvider {
    background-color: var(--white);
    border: solid 1px var(--inputBorder);
    border-radius: 0.1875rem;
    height: 6.25rem;
    width: 48%;
    background-position: center center;
    background-size: 70%;
    position: relative;
    float: left;
    cursor: pointer;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.toggleProvider:hover {
    background-color: var(--navigation);
    border: solid 1px var(--primaryColor);
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.toggleProvider:active {
    background-color: var(--navigation);
    border: solid 1px var(--primaryColorHover);
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.toggleProvider.amazon {
    background-image: url(/assets/images/Companies/AWS.png);
}

.toggleProvider.amazon.selected {
    background-image: url(/assets/images/Companies/AWS_Color.png);
}

.toggleProvider.microsoft {
    background-image: url(/assets/images/Companies/Azure.png);
}

.toggleProvider.microsoft.selected {
    background-image: url(/assets/images/Companies/Azure_Color.png);
}

::placeholder {
    color: var(--placeholder);
}

/* Style when highlighting a search. */
.ui-select-highlight {
    font-weight: bold;
}

.ui-select-offscreen {
    clip: rect(0 0 0 0) !important;
    width: 1px !important;
    height: 1px !important;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
    position: absolute !important;
    outline: 0 !important;
    left: 0rem !important;
    top: 0rem !important;
}

.ui-select-choices-row:hover {
    background-color: #f5f5f5;
}

/* Select2 theme */

/* Mark invalid Select2 */
.ng-dirty.ng-invalid > a.select2-choice {
    border-color: #d44950;
}

.select2-result-single {
    padding-left: 0;
}

.select2-locked > .select2-search-choice-close {
    display: none;
}

.select-locked > .ui-select-match-close {
    display: none;
}

body > .select2-container.open {
    z-index: 9999; /* The z-index Select2 applies to the select2-drop */
}

/* Handle up direction Select2 */
.ui-select-container[theme='select2'].direction-up .ui-select-match {
    border-radius: 0.25rem; /* FIXME hardcoded value :-/ */
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.ui-select-container[theme='select2'].direction-up .ui-select-dropdown {
    border-radius: 0.25rem; /* FIXME hardcoded value :-/ */
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    border-top-width: 1px; /* FIXME hardcoded value :-/ */
    border-top-style: solid;

    box-shadow: 0 -0.25rem 0.5rem rgba(0, 0, 0, 0.25);

    margin-top: -0.25rem; /* FIXME hardcoded value :-/ */
}

.ui-select-container[theme='select2'].direction-up .ui-select-dropdown .select2-search {
    margin-top: 0.25rem; /* FIXME hardcoded value :-/ */
}

.ui-select-container[theme='select2'].direction-up.select2-dropdown-open .ui-select-match {
    border-bottom-color: #5897fb;
}

/* Selectize theme */

/* Helper class to show styles when focus */
.selectize-input.selectize-focus {
    border-color: #007fbb !important;
}

/* Fix input width for Selectize theme */
.selectize-control > .selectize-input > input {
    width: 100%;
}

/* Fix dropdown width for Selectize theme */
.selectize-control > .selectize-dropdown {
    width: 100%;
}

/* Mark invalid Selectize */
.ng-dirty.ng-invalid > div.selectize-input {
    border-color: #d44950;
}

/* Handle up direction Selectize */
.ui-select-container[theme='selectize'].direction-up .ui-select-dropdown {
    box-shadow: 0 -0.25rem 0.5rem rgba(0, 0, 0, 0.25);

    margin-top: -0.125rem; /* FIXME hardcoded value :-/ */
}

/* Bootstrap theme */

/* Helper class to show styles when focus */
.btn-default-focus {
    color: #333;
    background-color: #ebebeb;
    border-color: #adadad;
    text-decoration: none;
    outline: 0.3125rem auto -webkit-focus-ring-color;
    outline-offset: -0.125rem;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0.5rem rgba(102, 175, 233, 0.6);
}

.ui-select-bootstrap .ui-select-toggle {
    position: relative;
}

.ui-select-bootstrap .ui-select-toggle > .caret {
    position: absolute;
    height: 0.625rem;
    top: 50%;
    right: 0.625rem;
    margin-top: -0.125rem;
}

/* Fix Bootstrap dropdown position when inside a input-group */
.input-group > .ui-select-bootstrap.dropdown {
    /* Instead of relative */
    position: static;
}

.input-group > .ui-select-bootstrap > input.ui-select-search.form-control {
    border-radius: 0.25rem; /* FIXME hardcoded value :-/ */
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group > .ui-select-bootstrap > input.ui-select-search.form-control.direction-up {
    border-radius: 0.25rem !important; /* FIXME hardcoded value :-/ */
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.ui-select-bootstrap > .ui-select-match > .btn {
    /* Instead of center because of .btn */
    text-align: left !important;
}

.ui-select-bootstrap > .ui-select-match > .caret {
    position: absolute;
    top: 45%;
    right: 0.9375rem;
}

.ui-disabled {
    background-color: #eceeef;
    border-radius: 0.25rem;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0.6;
    top: 0;
    left: 0;
    cursor: not-allowed;
}

/* See Scrollable Menu with Bootstrap 3 http://stackoverflow.com/questions/19227496 */
.ui-select-bootstrap > .ui-select-choices {
    width: 100%;
    height: auto;
    max-height: 12.5rem;
    overflow-x: hidden;
    margin-top: -1px;
}

body > .ui-select-bootstrap.open {
    z-index: 1000; /* Standard Bootstrap dropdown z-index */
}

.ui-select-multiple.ui-select-bootstrap {
    height: auto;
    padding: 0.1875rem 0.1875rem 0 0.1875rem;
}

.ui-select-multiple.ui-select-bootstrap input.ui-select-search {
    background-color: transparent !important; /* To prevent double background when disabled */
    border: none;
    outline: none;
    height: 1.666666em;
    margin-bottom: 0.1875rem;
}

.ui-select-multiple.ui-select-bootstrap .ui-select-match .close {
    font-size: 1.6em;
    line-height: 0.75;
}

.ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
    outline: 0;
    margin: 0 0.1875rem 0.1875rem 0;
}

.ui-select-multiple .ui-select-match-item {
    position: relative;
}

.ui-select-multiple .ui-select-match-item.dropping-before:before {
    content: '';
    position: absolute;
    top: 0;
    right: 100%;
    height: 100%;
    margin-right: 0.125rem;
    border-left: 1px solid #428bca;
}

.ui-select-multiple .ui-select-match-item.dropping-after:after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    height: 100%;
    margin-left: 0.125rem;
    border-right: 1px solid #428bca;
}

.ui-select-bootstrap .ui-select-choices-row > a {
    display: block;
    padding: 0.1875rem 1.25rem;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

.ui-select-bootstrap .ui-select-choices-row > a:hover,
.ui-select-bootstrap .ui-select-choices-row > a:focus {
    text-decoration: none;
    color: #262626;
    background-color: #f5f5f5;
}

.ui-select-bootstrap .ui-select-choices-row.active > a {
    color: #fff;
    text-decoration: none;
    outline: 0;
    background-color: #428bca;
}

.ui-select-bootstrap .ui-select-choices-row.disabled > a,
.ui-select-bootstrap .ui-select-choices-row.active.disabled > a {
    color: #777;
    cursor: not-allowed;
    background-color: #fff;
}

/* fix hide/show angular animation */
.ui-select-match.ng-hide-add,
.ui-select-search.ng-hide-add {
    display: none !important;
}

/* Mark invalid Bootstrap */
.ui-select-bootstrap.ng-dirty.ng-invalid > button.btn.ui-select-match {
    border-color: #d44950;
}

/* Handle up direction Bootstrap */
.ui-select-container[theme='bootstrap'].direction-up .ui-select-dropdown {
    box-shadow: 0 -0.25rem 0.5rem rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 44.6875rem) {
    .fullModal .wizard .icon {
        display: none;
    }

    .wizard input,
    .wizard select,
    .wizard label {
        width: 100%;
    }
}
