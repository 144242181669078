/* Button Styles */
.cdk-overlay-container,
.mopView {
    .button {
        display: inline-block;
        min-width: 9.375rem;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        margin-top: 0.625rem;
        line-height: 2.5rem;
        height: 2.5rem;
        border: none;
        background: none;
        vertical-align: middle;
        text-align: center;
        position: relative;
        z-index: 1;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
        background-color: var(--primaryColor);
        color: var(--white);
        overflow: hidden;
        -webkit-transition: color 0.2s;
        transition: color 0.2s;
        font-size: 0.875rem;
        font-weight: 600;
        font-family: 'Open Sans';
        text-transform: uppercase;
        border-radius: 1.25rem;
        -webkit-box-shadow: 0rem 0.1875rem 0.5625rem 0rem var(--primaryColorOpaque);
        -moz-box-shadow: 0rem 0.1875rem 0.5625rem 0rem var(--primaryColorOpaque);
        box-shadow: 0rem 0.1875rem 0.5625rem 0rem var(--primaryColorOpaque);
        cursor: pointer;
    }

    .button:hover {
        outline: 0;
        filter: brightness(90%);
        box-shadow: 0 0.125rem 0.1875rem 0 var(--primaryColorOpaque);
        box-shadow: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    .button:active {
        background: #648293;
        box-shadow: none;
        -webkit-transform: translateY(1px);
        transform: translateY(1px);
    }

    .buttonGrid {
        display: grid;
        grid-template-columns: 9.375rem auto 9.375rem;
    }

    .switch {
        position: absolute;
        width: 4.125rem;
        height: 4.125rem;
        font-size: 1.875rem;
        top: 1.25rem;
        right: 14.375rem;
        color: var(--stroke);
        text-transform: uppercase;
        text-align: center;
        line-height: 2.5rem;
        transition: 0.5s;
    }

    .switch .toggle {
        top: 0.625rem;
        z-index: 20;
    }

    .sendButton {
        background-color: #232f3e;
        width: 3.125rem;
        height: 3.125rem;
        font-size: 1.875rem;
        text-align: center;
        line-height: 3.125rem;
        border-radius: 0.4375rem;
        color: #fff;
        cursor: pointer;
        transition: 0.5s;
    }

    .sendButton.sending {
        background-color: var(--primaryColor);
        color: #fff;
    }

    .sendButton.sending .spinner {
        display: inline-block;
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 50%;
        background: transparent;
        border-top: 0.125rem solid white;
        border-right: 0.125rem solid white;
        border-bottom: 0.125rem solid transparent;
        border-left: 0.125rem solid transparent;
        -webkit-animation: loading 0.5s infinite linear;
        animation: loading 0.5s infinite linear;
    }

    .sendButton.sent::before {
        color: #fff;
    }

    .sendButton.sent {
        background-color: var(--green);
        color: #fff;
    }

    .sendButton:hover {
        opacity: 0.9;
    }

    .darkGrid {
        display: grid;
        grid-template-columns: 4.6875rem auto 4.6875rem;
        height: 4.0625rem;
        background-color: #232f3e;
        border-radius: 0.4375rem;
        box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadow);
    }

    .darkGrid .title {
        color: #000;
        font-family: 'Russo One';
        text-transform: uppercase;
        font-size: 1.25rem;
        line-height: 1.125rem;
        padding: 1.0625rem;
    }

    .expansionLabel .title {
        color: #000;
        font-family: 'Russo One';
        text-transform: uppercase;
        font-size: 1.25rem;
        line-height: 1.125rem;
        padding-left: 0;
        width: 0;
    }

    .darkGrid .subtitle {
        color: #fff;
        line-height: 1.25rem;
        font-weight: 600;
        font-family: 'Open Sans';
        font-size: 1rem;
        text-transform: uppercase;
        text-align: center;
        padding-top: 0.875rem;
        overflow: hidden;
    }

    .expansionLabel .subtitle {
        color: #fff;
        line-height: 1.125rem;
        font-weight: 600;
        font-family: 'Open Sans';
        font-size: 1.125rem;
        text-transform: uppercase;
        text-align: center;
        padding-top: 1.5625rem;
    }

    .switchGrid {
        grid-template-columns: auto;
        text-align: center;
        margin-right: 1.25rem;
        margin-top: 0.75rem;
        color: var(--icon);
        text-transform: uppercase;
        font-weight: 600;
        font-size: 0.8125rem;
    }

    .haInfo,
    .avwSiteInfo {
        color: var(--primaryColor);
        font-size: 1.25rem;
        line-height: 1rem;
        position: absolute;
        box-sizing: border-box;
        text-align: center;
        display: inline-block;
        top: 1.125rem;
        width: 1.6875rem;
    }

    .avwSiteInfo {
        top: 0.75rem;
    }

    .title .icon-ha,
    .title .icon-AdvancedOptions,
    .title .icon-NFAuthorizationSessionManagement {
        color: var(--primaryColor);
        font-size: 2.625rem;
        position: absolute;
        top: 0.75rem;
        left: 0.8125rem;
    }

    .AwsButton {
        background-size: 10%;
        background-position: left 1.25rem center;
        background-image: url(/assets/images/AwsLogo.png);
        background-repeat: no-repeat;
        padding-left: 6.25rem;
        padding-right: 6.25rem;
        padding-top: 0.9375rem;
        text-align: center;
        cursor: pointer;
        height: 4.375rem;
        width: 100%;
        z-index: 10;
        border: none;
        margin-bottom: 1.875rem;
        font-size: 0.9375rem;
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;
        background-color: #232f3e;
        border-radius: 0.4375rem;
        box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadow);
        -webkit-box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadow);
        -webkit-transition: box-shadow 0.3s;
        -moz-transition: box-shadow 0.3s;
        -ms-transition: box-shadow 0.3s;
        -o-transition: box-shadow 0.3s;
        transition: box-shadow 0.3s;
        transition: 0.3s;
    }

    .AwsButton.Azure {
        background-image: url(/assets/svgs/Azure.svg);
        background-color: #232f3e;
    }

    .AwsButton.Azure.disableLaunch {
        background-image: url(/assets/svgs/AzureDisabled.svg);
        background-color: var(--inputBorder);
        cursor: context-menu;
        color: var(--icon);
    }

    .AwsButton.disableLaunch:hover {
        filter: none;
    }

    .AwsButton.disableLaunch:active {
        filter: none;
        transform: none;
    }

    .AwsButton:hover {
        filter: brightness(90%);
        -webkit-box-shadow: 0rem 0.1875rem 0.1875rem 0rem var(--shadow);
        box-shadow: 0rem 0.1875rem 0.1875rem 0rem var(--shadow);
        -webkit-transition: box-shadow 0.3s;
        -moz-transition: box-shadow 0.3s;
        -ms-transition: box-shadow 0.3s;
        -o-transition: box-shadow 0.3s;
        transition: box-shadow 0.3s;
        transition: 0.3s;
    }

    .AwsButton:active {
        filter: brightness(80%);
        -webkit-box-shadow: 0rem 1px 1px 0rem var(--shadow);
        box-shadow: 0rem 1px 1px 0rem var(--shadow);
        -webkit-transition: box-shadow 0.3s;
        -moz-transition: box-shadow 0.3s;
        -ms-transition: box-shadow 0.3s;
        -o-transition: box-shadow 0.3s;
        transition: box-shadow 0.3s;
        transition: 0.3s;
    }

    // TODO Remove
    .AwsButton.disable {
        background-image: url(/assets/svgs/AWSDisabled.svg);
        background-color: var(--inputBorder);
        cursor: context-menu;
        color: var(--icon);
    }

    .AwsButton.Azure.disable {
        background-image: url(/assets/svgs/AzureDisabled.svg);
        background-color: var(--inputBorder);
        cursor: context-menu;
        color: var(--icon);
    }

    .AwsButton.disable:hover {
        filter: none;
    }

    .AwsButton.disable:active {
        filter: none;
        transform: none;
    }

    .disablebuttonote {
        color: var(--icon);
        margin-bottom: 0.625rem;
        text-align: center;
        position: relative;
        top: -1.25rem;
    }

    // TODO remove above

    .AwsButton .click {
        font-family: 'icomoon' !important;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        font-size: 2.875rem;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        right: 1.25rem;
        top: 0.8125rem;
        bottom: 0.9375rem;
        width: 2.5rem;
        background-size: contain;
        background-repeat: no-repeat;
        transition: 0.3s;
    }

    .AwsButton .click:before {
        content: '\e90b';
    }

    .launch {
        background-color: #232f3e;
        position: relative;
        text-align: left;
        width: 100%;
        margin-top: 0.3125rem;
        cursor: pointer;
        line-height: 2.5rem;
        border-radius: 0.4375rem;
        color: #fff;
        text-transform: uppercase;
        font-size: 0.875rem;
        font-weight: 600;
        padding-left: 0.625rem;
        transition: 0.3s;
    }

    .launch.ms {
        background-color: #0072c6;
    }

    .launch.ms .tap {
        color: #fff;
    }

    .launch.ms .tap .icon-ClickIcon:before {
        color: #fff;
    }

    .launch:hover {
        filter: brightness(90%);
        transition: 0.3s;
    }

    .launch:active {
        filter: brightness(80%);
        transition: 0.3s;
        transform: scale(0.99);
    }

    .launch .tap {
        position: absolute;
        right: 0.6875rem;
        color: var(--icon);
        top: 0rem;
        font-weight: 600;
        padding-left: 2.0625rem;
        background-size: 1.75rem;
        background-position: left;
    }

    .icon-ClickIcon {
        font-weight: 400;
    }

    .launch .tap.aws {
        background-image: url(/assets/images/AwsLogo.png);
    }

    .launch .tap.azure {
        background-image: url(/assets/images/gateways-azure.png);
    }

    .launch .tap.google {
        content: url(/assets/svgs/gcpmini.svg);
        height: 1.5625rem;
        top: 0.5rem !important;
    }

    .linkButton {
        cursor: pointer;
        margin-top: -1.625rem;
        color: var(--text);
        padding-left: 0.625rem;
        transition: 0.3s;
        font-weight: 600;
        width: fit-content;
    }

    .linkButton:hover {
        text-decoration: underline;
        color: var(--primaryColor);
        transition: 0.3s;
    }

    /* Export Styles */
    #ExportAction .title.action {
        font-size: 0.875rem;
        font-weight: 600;
        font-family: 'Open Sans';
        text-transform: uppercase;
        position: absolute;
        right: 0.625rem;
        top: -4.6875rem;
        line-height: 0.875rem;
        color: var(--white);
        width: auto;
        text-align: right;
        cursor: pointer;
        -webkit-transition: 0.5s;
        transition: 0.5s;
    }

    .buttonAction {
        font-family: 'icomoon' !important;
        line-height: 3.75rem;
        font-size: 1.875rem;
        text-align: center;
        color: var(--white);
        width: 3.75rem;
        height: 3.75rem;
        border-radius: 2.5rem;
        position: absolute;
        right: 2.125rem;
        top: 1.5625rem;
        background-color: var(--primaryColor);
        cursor: pointer;
        z-index: 24;
        -webkit-box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadow);
        -moz-box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadow);
        box-shadow: 0rem 1.25rem 1.4375rem 0rem var(--shadow);
        -webkit-transition: background-color 0.5s;
        -moz-transition: background-color 0.5s;
        -ms-transition: background-color 0.5s;
        -o-transition: background-color 0.5s;
        transition: background-color 0.5s;
    }

    .buttonAction.export:before {
        content: '\e90c';
    }

    .buttonAction.export {
        font-size: 2.625rem;
    }

    .buttonAction.export:hover {
        color: var(--white);
        background-color: var(--primaryColor);
        filter: brightness(90%);
        transition: background-color 1s;
        transition: 0.3s;
    }

    .buttonAction.export:active {
        background-color: var(--icon);
        transform: scale(0.97);
        transition: background-color 1s;
        transition: 0.3s;
    }

    .buttonAction.add:before {
        content: '\e901';
    }

    .buttonAction:hover {
        background-color: var(--primaryColor);
        filter: brightness(35%);
        transition: background-color 0.5s;
    }

    .buttonAction.add:hover {
        background-image: url(/assets/images/Icon_AddWhite.png);
    }

    .buttonAction:active {
        background-color: var(--primaryColorHover);
    }

    .buttonAction.add.minus {
        background-image: url(/assets/images/Icon_Minus.png);
        background-size: 40%;
        background-color: var(--red);
        -webkit-box-shadow: 0rem 0.375rem 0.625rem 0rem var(--redShadow);
        -moz-box-shadow: 0rem 0.375rem 0.625rem 0rem var(--redShadow);
        box-shadow: 0rem 0.375rem 0.625rem 0rem var(--redShadow);
    }

    .buttonAction.add.minus:hover {
        background-color: var(--red);
    }

    .buttonAction.add.minus:active {
        background-color: var(--red);
    }

    .button.alert {
        background-color: var(--red);
        -webkit-box-shadow: 0rem 0.375rem 0.625rem 0rem var(--redShadow);
        -moz-box-shadow: 0rem 0.375rem 0.625rem 0rem var(--redShadow);
        box-shadow: 0rem 0.375rem 0.625rem 0rem var(--redShadow);
    }

    .button.alert::before,
    .button.alert::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        bottom: 100%;
        left: 0;
        z-index: -1;
        -webkit-transition: -webkit-transform 0.2s;
        transition: transform 0.2s;
        -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
        transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
    }

    .button.alert::before {
        background: none;
    }

    .button.alert::after {
        background: var(--red);
    }

    .button.alert:hover {
        color: var(--white);
    }

    .button.alert:hover::before,
    .button.alert:hover::after {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    .button.alert:hover::after {
        -webkit-transition-delay: 0.175s;
        transition-delay: 0.175s;
    }

    .button.alert:active {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    .buttons {
        position: absolute;
        bottom: 0rem;
        width: 100%;
        padding: 1.25rem;
    }

    .button.clear {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
        -webkit-transition: color 0.2s;
        transition: color 0.2s;
        text-transform: uppercase;
        color: var(--stroke);
        background-color: var(--white);
        border-width: 1px;
        border-style: solid;
        border-color: var(--inputBorder);
        font-weight: bold;
        box-shadow: none;
    }

    .button.clear::before,
    .button.clear::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        bottom: 100%;
        left: 0;
        z-index: -1;
        -webkit-transition: -webkit-transform 0.2s;
        transition: transform 0.2s;
        -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
        transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
    }

    .button.clear::before {
        background: var(--primaryColor);
        filter: brightness(35%);
    }

    .button.clear::after {
        background: var(--primaryColor);
        filter: brightness(35%);
    }

    .button.clear:hover {
        color: var(--white);
        background-color: var(--red);
        filter: brightness(100%);
    }

    .button.clear:hover::before,
    .button.clear:hover::after {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        background-color: var(--red);
        filter: brightness(100%);
    }

    .button.clear:hover::after {
        -webkit-transition-delay: 0.175s;
        transition-delay: 0.175s;
        background-color: var(--red);
        filter: brightness(100%);
    }

    .button.clear:active {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        background-color: var(--red);
        filter: brightness(100%);
    }

    .buttonAdd.bannerOpen,
    .buttonAction.bannerOpen {
        top: 3.4375rem;
    }

    .buttonAdd {
        font-family: 'icomoon' !important;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: var(--white);
        line-height: 3.75rem;
        font-size: 1.875rem;
        width: 3.75rem;
        height: 3.75rem;
        border-radius: 2.5rem;
        position: absolute;
        right: 2.125rem;
        top: 1.5625rem;
        background-color: var(--primaryColor);
        cursor: pointer;
        overflow: hidden;
        z-index: 24;
        box-shadow: 0rem 1.25rem 1.4375rem 0rem var(--shadow);
        transition: background-color 0.5s;
    }

    div .ripple {
        border-radius: 50%;
        background-color: #ccc;
        position: absolute;
        transform: scale(0);
        animation: ripple 0.6s linear;
    }

    @keyframes ripple {
        to {
            transform: scale(2.5);
            opacity: 0;
        }
    }

    .buttonAdd:before {
        content: '\e937';
    }

    .buttonAdd:hover {
        color: var(--white);
        background-color: var(--primaryColor);
        filter: brightness(90%);
        transition: background-color 1s;
        transition: 0.3s;
    }

    .buttonAdd:active {
        background-color: var(--icon);
        transform: scale(0.97);
        transition: background-color 1s;
        transition: 0.3s;
    }

    .buttonAction.export:active {
        background-color: var(--icon);
        transform: scale(0.97);
        transition: background-color 1s;
    }

    .buttonAdd.minus:before {
        content: '\e943';
    }

    .buttonAdd.minus {
        color: var(--white);
        background-color: var(--red);
        -webkit-box-shadow: 0rem 0.375rem 0.625rem 0rem var(-redShadow);
        -moz-box-shadow: 0rem 0.375rem 0.625rem 0rem var(-redShadow);
        box-shadow: 0rem 0.375rem 0.625rem 0rem var(-redShadow);
        transition: background-color 0.5s;
    }

    .buttonAdd.minus:hover {
        color: var(--white);
        background-color: var(--red);
        filter: brightness(75%);
    }

    .buttonAdd.minus:active {
        background-color: var(--icon);
        transition: background-color 0.3s;
        transform: scale(0.97);
    }

    .save {
        font-size: 0.875rem;
        font-family: 'Open Sans';
        font-weight: 600;
        display: inline-block;
        min-width: 9.375rem;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        clear: both;
        overflow: hidden;
        height: 2.8125rem;
        padding: 0.8125rem;
        width: fit-content;
        text-transform: uppercase;
        text-align: center;
        border-radius: 0.4375rem;
        position: relative;
        border: 0;
        background: var(--primaryColor);
        color: white;
        white-space: nowrap;
        transition: all 150ms ease;
        cursor: pointer;
        box-shadow: 0 0.1875rem 0.5625rem 0 var(--primaryColorOpaque);
    }

    .save.confirm {
        background: var(--primaryColor);
    }

    .save:hover,
    .save:focus {
        outline: 0;
        filter: brightness(90%);
        box-shadow: 0 0.125rem 0.1875rem 0 var(--primaryColorOpaque);
        box-shadow: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    .save:active {
        background: #648293;
        box-shadow: none;
        -webkit-transform: translateY(1px);
        transform: translateY(1px);
    }

    .save:disabled {
        cursor: auto;
        pointer-events: none;
    }

    .save:disabled:hover {
        box-shadow: 0 1.875rem 1.875rem -1.25rem rgba(0, 0, 0, 0.4);
    }

    .save.done {
        background: #00dc5a;
        box-shadow: 0 1.875rem 1.875rem -1.25rem rgba(0, 220, 90, 0.4);
        -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 62.5rem;
        perspective: 62.5rem;
    }

    .save .spinner {
        display: inline-block;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background: transparent;
        border-top: 0.125rem solid white;
        border-right: 0.125rem solid white;
        border-bottom: 0.125rem solid transparent;
        border-left: 0.125rem solid transparent;
        -webkit-animation: loading 0.5s infinite linear;
        animation: loading 0.5s infinite linear;
    }

    .save.clear {
        color: var(--stroke);
        background-color: var(--navigation);
        border-width: 0.125rem;
        border-style: solid;
        border-color: var(--inputBorder);
        box-shadow: none;
        padding-top: 0.625rem;
    }

    .save.clear:hover,
    .save.clear:focus {
        outline: 0;
        background: var(--primaryColor);
        border-color: var(--primaryColor);
        color: #ffffff;
        filter: none;
        box-shadow: 0 0.125rem 0.1875rem 0 var(--primaryColorOpaque);
        box-shadow: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    .save.clear:active {
        background: #648293;
        border-color: #648293;
        color: #ffffff;
        box-shadow: none;
        -webkit-transform: translateY(1px);
        transform: translateY(1px);
    }

    .save.alert.confirm {
        background-color: var(--red);
        box-shadow: 0 0.1875rem 0.5625rem -0.125rem var(--red);
    }

    .save.alert.confirm:hover,
    .save.alert.confirm:focus {
        filter: brightness(90%);
        box-shadow: 0 0.1875rem 0.1875rem -0.125rem var(--red);
        box-shadow: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    .save.alert.confirm:active {
        background: #648293;
        box-shadow: none;
        -webkit-transform: translateY(1px);
        transform: translateY(1px);
    }

    .cloneButton .icon {
        background-image: url(/assets/svgs/clone.svg);
        background-size: 82%;
        left: 0.6875rem;
        top: 0.9375rem;
    }

    .cloneButton .tapIcon {
        font-size: 3.25rem;
        line-height: 4.8125rem;
        text-align: center;
        color: var(--icon);
    }

    .cloneButton .buttonText {
        color: var(--white);
        font-size: 1.25rem;
        text-transform: uppercase;
        line-height: 5rem;
    }

    .cloneButton .content {
        color: var(--white);
        font-size: 0.875rem;
        text-align: left;
        padding-top: 1rem;
        font-weight: 400;
        width: 100%;
        position: relative;
        font-size: 1.125rem;
        margin-bottom: 0.625rem;
        margin-left: 1.4375rem;
    }

    .cloneButton {
        display: grid;
        grid-template-columns: 4.6875rem auto 9.375rem 4.6875rem;
        background-color: #2d2e34;
        border-radius: 0.4375rem;
        margin-top: 1.25rem;
        height: 5rem;
        cursor: pointer;
        box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadow);
        -webkit-box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadow);
        -webkit-transition: box-shadow 0.3s;
        -moz-transition: box-shadow 0.3s;
        -ms-transition: box-shadow 0.3s;
        -o-transition: box-shadow 0.3s;
        transition: box-shadow 0.3s;
        transition: 0.3s;
    }

    .cloneButton:hover {
        filter: brightness(90%);
        -webkit-box-shadow: 0rem 0.1875rem 0.1875rem 0rem var(--shadow);
        box-shadow: 0rem 0.1875rem 0.1875rem 0rem var(--shadow);
        -webkit-transition: box-shadow 0.3s;
        -moz-transition: box-shadow 0.3s;
        -ms-transition: box-shadow 0.3s;
        -o-transition: box-shadow 0.3s;
        transition: box-shadow 0.3s;
        transition: 0.3s;
    }

    .cloneButton:active {
        filter: brightness(80%);
        -webkit-box-shadow: 0rem 1px 1px 0rem var(--shadow);
        box-shadow: 0rem 1px 1px 0rem var(--shadow);
        -webkit-transition: box-shadow 0.3s;
        -moz-transition: box-shadow 0.3s;
        -ms-transition: box-shadow 0.3s;
        -o-transition: box-shadow 0.3s;
        transition: box-shadow 0.3s;
        transition: 0.3s;
    }

    .avwSiteStaged:before {
        content: url(/assets/svgs/Staged.svg);
    }

    .avwSiteDeployable:before {
        content: url(/assets/svgs/ReadytoDeploy.svg);
    }

    .avwSitePushed:before {
        content: url(/assets/svgs/Pushed.svg);
    }

    @-webkit-keyframes loading {
        to {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes loading {
        to {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes shake {
        10%,
        90% {
            -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
        }
        20%,
        80% {
            -webkit-transform: translate3d(0.125rem, 0, 0);
            transform: translate3d(0.125rem, 0, 0);
        }
        30%,
        50%,
        70% {
            -webkit-transform: translate3d(-0.25rem, 0, 0);
            transform: translate3d(-0.25rem, 0, 0);
        }
        40%,
        60% {
            -webkit-transform: translate3d(0.25rem, 0, 0);
            transform: translate3d(0.25rem, 0, 0);
        }
    }

    @keyframes shake {
        10%,
        90% {
            -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
        }
        20%,
        80% {
            -webkit-transform: translate3d(0.125rem, 0, 0);
            transform: translate3d(0.125rem, 0, 0);
        }
        30%,
        50%,
        70% {
            -webkit-transform: translate3d(-0.25rem, 0, 0);
            transform: translate3d(-0.25rem, 0, 0);
        }
        40%,
        60% {
            -webkit-transform: translate3d(0.25rem, 0, 0);
            transform: translate3d(0.25rem, 0, 0);
        }
    }

    .showSmall {
        display: none;
    }

    .upgradebadge {
        min-width: 5rem;
        font-size: 0.75rem;
        padding: 0.25rem;
        height: fit-content;
        margin-left: 1.5rem;
        border-radius: 1rem;
        font-family: 'Open Sans';
        font-weight: 800;
    }

    @media only screen and (max-width: 75rem) {
        .hideSmall {
            display: none;
        }
        .showSmall {
            display: inline;
        }
    }

    @media only screen and (max-width: 87.5rem) {
        .AwsButton {
            padding-top: 1.25rem;
            font-size: 0.6875rem;
        }
    }
}

.buttonAdd:before {
    content: '\e937';
}

.buttonAdd:hover {
    color: var(--white);
    background-color: var(--primaryColor);
    filter: brightness(90%);
    transition: background-color 1s;
    transition: 0.3s;
}

.buttonAdd:active {
    background-color: var(--icon);
    transform: scale(0.97);
    transition: background-color 1s;
    transition: 0.3s;
}

.buttonAction.export:active {
    background-color: var(--icon);
    transform: scale(0.97);
    transition: background-color 1s;
}

.buttonAdd.minus:before {
    content: '\e943';
}

.buttonAdd.minus {
    color: var(--white);
    background-color: var(--red);
    -webkit-box-shadow: 0rem 0.375rem 0.625rem 0rem var(-redShadow);
    -moz-box-shadow: 0rem 0.375rem 0.625rem 0rem var(-redShadow);
    box-shadow: 0rem 0.375rem 0.625rem 0rem var(-redShadow);
    transition: background-color 0.5s;
}

.buttonAdd.minus:hover {
    color: var(--white);
    background-color: var(--red);
    filter: brightness(75%);
}

.buttonAdd.minus:active {
    background-color: var(--icon);
    transition: background-color 0.3s;
    transform: scale(0.97);
}

.save {
    font-size: 0.875rem;
    font-family: 'Open Sans';
    font-weight: 600;
    display: inline-block;
    min-width: 9.375rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    clear: both;
    overflow: hidden;
    height: 2.8125rem;
    padding: 0.8125rem;
    width: fit-content;
    text-transform: uppercase;
    text-align: center;
    border-radius: 0.4375rem;
    position: relative;
    border: 0;
    background: var(--primaryColor);
    color: white;
    white-space: nowrap;
    transition: all 150ms ease;
    cursor: pointer;
    box-shadow: 0 0.1875rem 0.5625rem 0 var(--primaryColorOpaque);
}

.save.confirm {
    background: var(--primaryColor);
}

.save:hover,
.save:focus {
    outline: 0;
    filter: brightness(90%);
    box-shadow: 0 0.125rem 0.1875rem 0 var(--primaryColorOpaque);
    box-shadow: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.save:active {
    background: #648293;
    box-shadow: none;
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
}

.save:disabled {
    cursor: auto;
    pointer-events: none;
}

.save:disabled:hover {
    box-shadow: 0 1.875rem 1.875rem -1.25rem rgba(0, 0, 0, 0.4);
}

.save.done {
    background: #00dc5a;
    box-shadow: 0 1.875rem 1.875rem -1.25rem rgba(0, 220, 90, 0.4);
    -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 62.5rem;
    perspective: 62.5rem;
}

.save .spinner {
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: transparent;
    border-top: 0.125rem solid white;
    border-right: 0.125rem solid white;
    border-bottom: 0.125rem solid transparent;
    border-left: 0.125rem solid transparent;
    -webkit-animation: loading 0.5s infinite linear;
    animation: loading 0.5s infinite linear;
}

.save.clear {
    color: var(--stroke);
    background-color: var(--navigation);
    border-width: 0.125rem;
    border-style: solid;
    border-color: var(--inputBorder);
    box-shadow: none;
    padding-top: 0.625rem;
}

.save.clear:hover,
.save.clear:focus {
    outline: 0;
    background: var(--primaryColor);
    border-color: var(--primaryColor);
    color: #ffffff;
    filter: none;
    box-shadow: 0 0.125rem 0.1875rem 0 var(--primaryColorOpaque);
    box-shadow: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.save.clear:active {
    background: #648293;
    border-color: #648293;
    color: #ffffff;
    box-shadow: none;
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
}

.save.alert.confirm {
    background-color: var(--red);
    box-shadow: 0 0.1875rem 0.5625rem -0.125rem var(--red);
}

.save.alert.confirm:hover,
.save.alert.confirm:focus {
    filter: brightness(90%);
    box-shadow: 0 0.1875rem 0.1875rem -0.125rem var(--red);
    box-shadow: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.save.alert.confirm:active {
    background: #648293;
    box-shadow: none;
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
}

.cloneButton .icon {
    background-image: url(/assets/svgs/clone.svg);
    background-size: 82%;
    left: 0.6875rem;
    top: 0.9375rem;
}

.cloneButton .tapIcon {
    font-size: 3.25rem;
    line-height: 4.8125rem;
    text-align: center;
    color: var(--icon);
}

.cloneButton .buttonText {
    color: var(--white);
    font-size: 1.25rem;
    text-transform: uppercase;
    line-height: 5rem;
}

.cloneButton .content {
    color: var(--white);
    font-size: 0.875rem;
    text-align: left;
    padding-top: 1rem;
    font-weight: 400;
    width: 100%;
    position: relative;
    font-size: 1.125rem;
    margin-bottom: 0.625rem;
    margin-left: 1.4375rem;
}

.cloneButton {
    display: grid;
    grid-template-columns: 4.6875rem auto 9.375rem 4.6875rem;
    background-color: #2d2e34;
    border-radius: 0.4375rem;
    margin-top: 1.25rem;
    height: 5rem;
    cursor: pointer;
    box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadow);
    -webkit-box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadow);
    -webkit-transition: box-shadow 0.3s;
    -moz-transition: box-shadow 0.3s;
    -ms-transition: box-shadow 0.3s;
    -o-transition: box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: 0.3s;
}

.cloneButton:hover {
    filter: brightness(90%);
    -webkit-box-shadow: 0rem 0.1875rem 0.1875rem 0rem var(--shadow);
    box-shadow: 0rem 0.1875rem 0.1875rem 0rem var(--shadow);
    -webkit-transition: box-shadow 0.3s;
    -moz-transition: box-shadow 0.3s;
    -ms-transition: box-shadow 0.3s;
    -o-transition: box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: 0.3s;
}

.cloneButton:active {
    filter: brightness(80%);
    -webkit-box-shadow: 0rem 1px 1px 0rem var(--shadow);
    box-shadow: 0rem 1px 1px 0rem var(--shadow);
    -webkit-transition: box-shadow 0.3s;
    -moz-transition: box-shadow 0.3s;
    -ms-transition: box-shadow 0.3s;
    -o-transition: box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: 0.3s;
}
