/* Growler Alert Styling */

#Growler {
    position: fixed;
    right: 1.25rem;
    bottom: -6.25rem;
    min-width: 18.75rem;
    z-index: 9999999;
    max-width: 31.25rem;
}

#NotificationsMenu .growler {
    position: relative;
    width: 100%;
    margin-bottom: 1.25rem;
    opacity: 1;
}

.growler {
    background-color: var(--navigation);
    min-height: 6.5625rem;
    min-width: 20.875rem;
    font-size: 1rem;
    color: #000;
    text-align: left;
    border-radius: 0.375rem;
    -webkit-box-shadow: 0rem 0.75rem 1.4375rem 0rem rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0rem 0.75rem 1.4375rem 0rem rgba(0, 0, 0, 0.1);
    box-shadow: 0rem 0.75rem 1.4375rem 0rem rgba(0, 0, 0, 0.1);
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    opacity: 0;
}

#Growler.open {
    bottom: 1.25rem;
}

.growler.open {
    opacity: 1;
}

.growler .time {
    font-size: 0.8125rem;
    color: #fff;
    position: absolute;
    top: 0.4375rem;
    right: 0.625rem;
    font-weight: 600;
    font-family: 'Open Sans';
}

.growler.error .title {
    background-color: #ff104b;
}

.growler.success .title {
    background-color: #03db5c;
}

.growler.warning .title {
    background-color: #ffc103;
}

.growler.info .title {
    background-color: #0971ff;
}

.growler.bug .title {
    background-color: #ff104b;
}

.growler .title {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    font-size: 0.875rem;
    font-weight: bold;
    color: #fff;
    line-height: 1.875rem;
    text-transform: uppercase;
    background-color: #03db5c;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    font-weight: 600;
    font-family: 'Open Sans';
}

.growler .subtitle {
    padding-top: 0.625rem;
    font-size: 0.9375rem;
    font-weight: bold;
    padding-left: 4.6875rem;
    padding-right: 0.625rem;
}

.growler .content {
    font-size: 0.8125rem;
    font-weight: normal;
    padding-left: 4.6875rem;
    padding-right: 0.625rem;
    margin-top: 0.125rem;
    line-height: 0.875rem;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 400;
    font-family: 'Open Sans';
    color: var(--tableText);
    padding-bottom: 0.625rem;
}

.growler .icon {
    width: 3.4375rem;
    height: 3.4375rem;
    background-position: center center;
    background-size: 80%;
    position: absolute;
    left: 0.625rem;
    top: 2.5rem;
}

.growler.error .icon {
    background-image: url(/assets/svgs/Growl_Error.svg);
}

.growler.success .icon {
    background-image: url(/assets/svgs/Growl_Success.svg);
}

.growler.info .icon {
    background-image: url(/assets/svgs/Growl_Info.svg);
}

.growler.warning .icon {
    background-image: url(/assets/svgs/Growl_Warning.svg);
}

.growler.bug .icon {
    background-image: url(/assets/svgs/Growl_Bug.svg);
}

#NotificationsMenu {
    z-index: 9999;
    width: 0rem;
    position: fixed;
    top: 0rem;
    bottom: 0rem;
    left: -5rem;
    background-color: var(--background);
    border-right: 0.1875rem solid var(--inputBorder);
    transition: 0.3s;
    opacity: 0;
    overflow: hidden;
}

#NotificationsList {
    overflow-x: visible;
    overflow-y: auto;
    position: absolute;
    left: 0rem;
    right: 0rem;
    bottom: 0rem;
    top: 4.375rem;
    clear: both;

    padding: 1.375rem;
    padding-top: 0rem;
}

#NotificationsMenu.open {
    width: 23.75rem;
    left: 0rem;
    opacity: 1;
}

#NotificationsList.open {
    width: 23.75rem;
    left: 0rem;
    opacity: 1;
}

#NotificationsMenu .header.icon {
    background-image: unset;
    text-align: center;
    font-size: 1.875rem;
    font-weight: 800;
    color: var(--icon);
    line-height: 1.875rem;
    position: relative;
    float: left;
    width: 1.875rem;
    height: 1.875rem;
    margin: 1.25rem 2.0625rem 1.25rem 1.25rem;
    background-size: 75%;
    left: 0.625rem;
    top: 0rem;
}

#NotificationsMenu .header.title {
    font-weight: 300;
    font-family: 'Russo One';
    font-size: 1.5rem;
    line-height: 4.875rem;
    color: var(--primaryColor);
    filter: brightness(50%);
    padding-left: 1.25rem;
    text-transform: uppercase;
}

.NoNotificationsText {
    margin: 0rem;
    color: var(--tableText);
    font-weight: 600;
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 0.875rem;
}

#NotificationMenuClose {
    color: var(--icon);
    font-size: 2.1875rem;
    text-align: center;
    font-weight: 600;
    line-height: 2.5rem;
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    right: 1rem;
    top: 1.0625rem;
    cursor: pointer;
    z-index: 20;
    transition: 0.3s;
}

#NotificationMenuClose:hover {
    filter: brightness(50%);
    transition: 0.3s;
}

#NotificationMenuClose:active {
    filter: none;
    transform: scale(0.95);
    transition: 0.3s;
}

.sev {
    height: 4rem;
    background-size: 40%;
    background-position: center center;
    position: relative;
    float: left;
}

.sevError,
.sevMajor,
.sevCritical {
    background-image: url(/assets/svgs/Growl_Error.svg);
}

.sevWarning,
.sevMinor {
    background-image: url(/assets/svgs/Growl_Warning.svg);
}

.sevInfo {
    background-image: url(/assets/svgs/Growl_Info.svg);
}

.sevSuccess {
    background-image: url(/assets/svgs/Growl_Success.svg);
}
