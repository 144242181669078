/* CSS Animation Definitions */
@-webkit-keyframes LOADING {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        background: var(--primaryColor);
    }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
        background: var(--navigation);
    }
    100% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        background: var(--primaryColor);
    }
}

@keyframes LOADING {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        background: var(--primaryColor);
    }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
        background: var(--navigation);
    }
    100% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        background: var(--primaryColor);
    }
}

@-webkit-keyframes LOADINGREV {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        background: var(--navigation);
    }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
        background: var(--primaryColor);
    }
    100% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        background: var(--navigation);
    }
}

@keyframes LOADINGREV {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        background: var(--navigation);
    }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
        background: var(--primaryColor);
    }
    100% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        background: var(--navigation);
    }
}

@-webkit-keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -2.1875rem;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -7.75rem;
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -2.1875rem;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -7.75rem;
    }
}

@-webkit-keyframes color {
    100%,
    0% {
        stroke: var(--stroke);
    }
    40% {
        stroke: var(--stroke);
    }
    66% {
        stroke: var(--stroke);
    }
    80%,
    90% {
        stroke: var(--stroke);
    }
}

@keyframes color {
    100%,
    0% {
        stroke: var(--stroke);
    }
    40% {
        stroke: var(--stroke);
    }
    66% {
        stroke: var(--stroke);
    }
    80%,
    90% {
        stroke: var(--stroke);
    }
}

@-webkit-keyframes anim5 {
    to {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

@keyframes anim5 {
    to {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}
