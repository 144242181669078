.auth0-lock.auth0-lock .auth0-lock-widget {
    min-width: 37.5rem;
}

.auth0-lock-custom-icon {
    width: 0.9375rem;
    height: 0.9375rem;
}

.auth0-lock.auth0-lock .auth0-lock-input-wrap input.auth0-lock-input {
    font-size: 0.9375rem;
}

.auth0-lock.auth0-lock .auth0-lock-badge-bottom {
    display: none;
}

@media only screen and (min-width: 30.0625rem) {
    .auth0-lock.auth0-lock.auth0-lock-opened form.auth0-lock-widget {
        box-shadow: 0rem 0rem 2.5rem 0.25rem var(--shadow);
    }
}

@media only screen and (max-width: 44.6875rem) {
    .auth0-lock.auth0-lock .auth0-lock-widget {
        min-width: unset;
    }

    .auth0-lock.auth0-lock .auth0-lock-widget {
        min-width: unset;
    }
}
