/* List Styles */
.itemGraphic {
    position: absolute;
    right: 1.125rem;
    font-size: 2.1875rem;
    line-height: 2.1875rem;
    top: 0.8125rem;
    opacity: 0.4;
    text-align: center;
}

.listItem:hover .itemGraphic::before {
    color: var(--white);
}

.listItem .itemGraphic::before {
    color: var(--tableText);
}

.listItem .itemGraphic {
    right: 0.3125rem;
    font-size: 1.5625rem;
    line-height: 2.1875rem;
    top: 1px;
}

.listItem {
    font-weight: 600;
    font-family: 'Open Sans', Arial, sans-serif;
    line-height: 2.25rem;
    height: 2.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    font-size: 0.875rem;
    position: relative;
    color: var(--tableText);
    cursor: pointer;
    border-bottom: solid var(--inputBorder) .1rem;
    background-position: right 1.25rem top 0.6875rem;
    background-size: auto 1.0625rem;
    transition: 0.01s;
    white-space: nowrap;
}

.listItem:hover {
    background-color: var(--primaryColor);
    border-bottom: solid var(--inputBorder) .1rem;
    color: var(--white);
    transition: 0.1s;
}

.moveButtons {
    position: relative;
    float: left;
    padding-top: 10rem;
}

.moveLeft:before {
    content: '\e908';
}

.moveLeft {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    text-align: center;
    font-weight: 600;
    font-variant: normal;
    text-transform: none;
    font-size: 1.875rem;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 1.875rem;
    color: var(--stroke);
    cursor: pointer;
    background-size: contain;
    background-position: center center;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.moveLeft:hover {
    filter: brightness(50%);
    transition: 0.5s;
}

.moveLeft:active {
    color: var(--inputBorder);
    transform: scale(0.97);
    transition: 0.3s;
}

.moveRight:before {
    content: '\e909';
}

.moveRight {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    text-align: center;
    font-weight: 600;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 1.875rem;
    font-size: 1.875rem;
    color: var(--stroke);
    cursor: pointer;
    background-size: contain;
    background-position: center center;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.moveRight:hover {
    filter: brightness(50%);
    transition: 0.5s;
}

.moveRight:active {
    color: var(--inputBorder);
    transform: scale(0.97);
    transition: 0.3s;
}

.formList {
    font-weight: 300;
    font-family: 'Open Sans', Arial, sans-serif;
    height: 26.3125rem;
    width: 100%;
    border-color: var(--inputBorder);
    border-style: solid;
    border-width: .1rem;
    border-radius: 0.1875rem;
    padding-left: 0rem;
    padding-right: 0rem;
    outline: none;
    box-sizing: border-box;
    font-size: 1.125rem;
    background-color: var(--navigation);
    overflow-y: auto;
}

.leftList {
    padding-right: 0.625rem;
    width: 44%;
    position: relative;
    float: left;
}

.rightList {
    padding-left: 0.625rem;
    width: 44%;
    position: relative;
    float: left;
}
