/* Browser Specific Global Styling */

::-webkit-scrollbar {
    width: 0.875rem;
    height: 1.125rem;
}

::-webkit-scrollbar-thumb {
    min-height: 2.125rem;
    border: 0.25rem solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 0.4375rem;
    border-radius: 0.4375rem;
    background-color: var(--inputBorder);
}

::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}

::-webkit-scrollbar * {
    background: transparent;
}

::-webkit-input-placeholder {
    color: var(--inputBorder);
}

:-moz-placeholder {
    color: var(--inputBorder);
    opacity: 1;
}

::-moz-placeholder {
    color: var(--inputBorder);
    opacity: 1;
}

:-ms-input-placeholder {
    color: var(--inputBorder);
}

::-ms-input-placeholder {
    color: var(--inputBorder);
}
