.footerLabel {
    padding-left: 0rem;
    line-height: 2.5rem;
    height: 2.5rem;
    color: var(--stroke);
    font-size: 0.875rem;
    font-weight: bold;
    padding-top: 0rem;
    z-index: 10;
    padding-top: 0rem;
    text-align: left;
    width: 100%;
    font-weight: 600;
    font-family: 'Open Sans', Arial, sans-serif;
    position: relative;
}
