.cardButton {
    text-align: center;
    width: 100%;
    background-color: var(--navigation);
    border-radius: 0.4375rem;
    -webkit-box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadow);
    box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadow);
    cursor: pointer;
    position: relative;
    font-weight: 400;
    font-family: 'Open Sans';
}

.cardButton .icon {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin-top: 1.5625rem;
    width: 100%;
    height: 3.125rem;
    position: relative;
    clear: both;
    left: 0rem;
    margin-top: 1.25rem;
    top: 0rem;
    margin-bottom: 1.25rem;
}

.cardButton .step {
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 0.9375rem;
    background-color: #cfd1d6;
    color: var(--black);
    text-align: center;
    line-height: 1.875rem;
    font-size: 1.125rem;
    position: absolute;
    left: 0.625rem;
    top: 0.625rem;
}

.cardButton .icon {
    height: 7.5rem;
}

.cardButton .title {
    color: var(--tableTitle);
    font-size: 1.375rem;
}

.cardButton .subtitle {
    font-size: 0.875rem;
    padding: 0.625rem;
}

.cardButton .icon.video {
    background-image: url(/assets/images/welcome/Video.png);
}

.cardButton .icon.tools {
    background-image: url(/assets/images/welcome/Tools.png);
}

.cardButton .icon.computer {
    background-image: url(/assets/images/welcome/Computer.png);
}

.cardButton .contents {
    color: var(--icon);
    font-size: 2rem;
    position: relative;
    float: left;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    width: 100%;
    background: none;
    line-height: 2.1875rem;
    text-transform: none;
    height: auto;
    font-weight: 400;
    font-family: 'Open Sans';
    text-align: center;
    clear: both;
    margin-bottom: 1.25rem;
}

.buttonCard {
    max-width: 20rem;
    width: 20rem;
    min-height: 24.6875rem;
    position: relative;
    float: none;
    display: inline-block;
    cursor: pointer;
    padding-bottom: 1.5625rem;
    margin: 0 auto;
    margin-right: 1.25rem;
    margin-left: 1.25rem;
    margin-top: 1.25rem;
    background-color: var(--navigation);
    -webkit-box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadow);
    box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadow);
    -webkit-transition: box-shadow 0.3s;
    -moz-transition: box-shadow 0.3s;
    -ms-transition: box-shadow 0.3s;
    -o-transition: box-shadow 0.3s;
    transition: box-shadow 0.3s;
    border-radius: 0.4375rem;
}

.buttonCard .title {
    font-size: 1rem;
    color: var(--tableText);
    text-align: center;
    text-transform: uppercase;
    padding-top: 0rem;
    font-weight: 600;
    font-family: 'Open Sans';
    position: absolute;
    top: 13.4375rem;
}

.buttonCard .buttonArea {
    position: absolute;
    bottom: 2.5rem;
}

.buttonCard .subButton {
    position: absolute;
    bottom: 0.625rem;
}

.buttonCard .content {
    color: var(--text);
    font-size: 0.8125rem;
    height: 5rem;
    margin-top: 0.9375rem;
    margin-bottom: 1.25rem;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    padding-top: 1.875rem;
    bottom: 5rem;
    position: absolute;
    font-weight: 400;
    font-family: 'Open Sans';
}

.buttonCard.shadow {
    box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadow);
}

.buttonCard.shadow .icon {
    background-size: 50%;
    background-position: center top;
}

.buttonCard:hover {
    filter: brightness(99.5%);
    -webkit-box-shadow: 0rem 0.1875rem 0.1875rem 0rem var(--shadow);
    box-shadow: 0rem 0.1875rem 0.1875rem 0rem var(--shadow);
    -webkit-transition: box-shadow 0.3s;
    -moz-transition: box-shadow 0.3s;
    -ms-transition: box-shadow 0.3s;
    -o-transition: box-shadow 0.3s;
    transition: box-shadow 0.3s;
}

.buttonCard:active {
    filter: brightness(99%);
    -webkit-box-shadow: 0rem 1px 1px 0rem var(--shadow);
    box-shadow: 0rem 1px 1px 0rem var(--shadow);
    -webkit-transition: box-shadow 0.3s;
    -moz-transition: box-shadow 0.3s;
    -ms-transition: box-shadow 0.3s;
    -o-transition: box-shadow 0.3s;
    transition: box-shadow 0.3s;
}

.buttonCard .icon {
    position: absolute;
    top: 1.875rem;
    left: 0.625rem;
    right: 0.625rem;
    height: 11.5625rem;
    background-position: center bottom;
    background-size: contain;
    z-index: unset;
    width: unset;
    min-width: unset;
    min-height: unset;
}

.card {
    text-align: left;
    width: 23.4375rem;
    height: 22.375rem;
    background-color: var(--navigation);
    border-radius: 0.4375rem;
    position: relative;
    display: inline-block;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-bottom: 1.25rem;
    box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadow);
    -webkit-box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadow);
    -webkit-transition: box-shadow 0.3s;
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.card .item,
.cardStack .item {
    font-size: 0.9375rem;
    color: var(--tableText);
    padding-left: 3.125rem;
    height: 3.75rem;
    line-height: 3.75rem;
    width: 100%;
    position: relative;
    float: left;
    clear: both;
    background-position: right 1.25rem center;
    background-size: 1.75rem;
    font-weight: 600;
    font-family: 'Open Sans';
}

.cardStack .item.clicker {
    cursor: pointer;
}

.cardStack .item.clicker:hover {
    color: var(--primaryColor);
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.cardStack .item {
    padding-left: 1.25rem;
    background-position: right 1.25rem top 0.6875rem;
    border-bottom: solid var(--offWhite);
    border-width: unset;
}

#NetworkTimeline {
  [class*=" icon-"] {
    font-family: "icomoon" !important;
  }
  .timeline.item {
    line-height: 1.125rem;
    height: auto;
    padding-top: 0.625rem;
  }
}

.card .title {
    font-size: 0.9375rem;
    text-transform: uppercase;
    color: #434c5e;
    line-height: 3.125rem;
    height: 3.125rem;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 4.6875rem;
    padding-left: 3.125rem;
    border-bottom: 0.1875rem solid var(--primaryColor);
    width: 100%;
    position: relative;
    float: left;
    clear: both;
    font-weight: 600;
    font-family: 'Open Sans';
    cursor: pointer;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.card .title:hover {
    color: var(--primaryColor);
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.card .title:active {
    color: var(--primaryColor);
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.card .area,
.cardStack .area {
    width: 100%;
    position: relative;
    float: left;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 18.75rem;
}

.fullModal .noData {
    width: 100%;
    height: 90%;
    top: 0rem;
    position: absolute;
    background-image: url(/assets/svgs/nodata.svg);
    background-position: top 3.125rem center;
    background-size: 11.25rem;
    color: var(--icon);
    opacity: 0.25;
    font-size: 1.5rem;
    text-align: center;
    padding-right: 1.875rem;
    line-height: 28.3125rem;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Open Sans';
}

.cardStack .clickable,
.card .clickable {
    cursor: pointer;
    color: var(--primaryColor);
}

.cardStack .clickable:hover,
.card .clickable:hover {
    text-decoration: underline;
}

.cardStack {
    background-color: var(--navigation);
    overflow: visable;
    border-radius: 0.4375rem;
    height: 7.5rem;
    width: 100%;
    float: left;
    position: relative;
    background-position: bottom 0.625rem right 0.625rem;
    background-size: 25%;
    margin-top: 1.5625rem;
    margin-right: 1px;
    margin-left: 1px;
    -moz-box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadow);
    -webkit-box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadow);
    box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadow);
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.cardStack.clickable:hover {
    opacity: 0.987;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale3d(1.05, 1.05, 1);
    transform: scale3d(1.05, 1.05, 1);
    -webkit-box-shadow: var(--shadowsHover);
    box-shadow: var(--shadowsHover);
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.cardStack.clickable .icon {
    position: absolute;
    right: 0.3125rem;
    top: 0.3125rem;
    text-align: center;
    color: var(--icon);
    background-image: unset;
    width: 1.875rem;
    height: 1.875rem;
    line-height: 1.875rem;
    font-size: 1.875rem;
    left: unset;
    z-index: unset;
}

.cardStack.openable {
    cursor: pointer;
}

.cardStack.openable:hover {
    opacity: 0.987;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale3d(1.05, 1.05, 1);
    transform: scale3d(1.05, 1.05, 1);
    -webkit-box-shadow: var(--shadowsHover);
    box-shadow: var(--shadowsHover);
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.cardStack.full {
    width: 100%;
    overflow: hidden;
}

.cardStack input {
    height: 2.5rem;
    line-height: 2.5rem;
}

.cardStack .title.noline {
    border-bottom: none;
}

.cost {
    position: absolute;
    right: 1.25rem;
    top: 0.625rem;
    text-align: right;
    color: var(--green);
    font-size: 2.375rem;
}

.cardStack .title,
.fullModal .cardStack .title {
    color: var(--tableText);
    font-size: 0.9375rem;
    text-transform: uppercase;
    padding-left: 1.125rem;
    padding-right: 0.625rem;
    background-position: top 0.5625rem right 0.5rem;
    background-size: 1.5625rem 1.5625rem;
    height: 2.9375rem;
    line-height: 2.9375rem;
    border-bottom: var(--primaryColor) solid 0.1875rem;
    font-weight: bold;
    z-index: 10;
    padding-top: 0rem;
    text-align: left;
    width: 100%;
    font-weight: 600;
    font-family: 'Open Sans', Arial, sans-serif;
    position: relative;
}

.cardStack .title.flat {
    width: 50%;
    border-bottom: none;
    line-height: 1.25rem;
    padding-top: 0.625rem;
}

.cardStack .subtitle {
    font-size: 2.5rem;
    width: 100%;
    position: relative;
    float: left;
    text-align: center;
    color: var(--tableText);
    line-height: 4.375rem;
    font-weight: 600;
    font-family: 'Open Sans';
}

.card1 {
    opacity: 0.6;
    border-radius: 0.625rem;
    position: absolute;
    left: 0.875rem;
    right: 0.875rem;
    top: -0.625rem;
    background-color: var(--navigation);
    height: 1.125rem;
}

.card2 {
    opacity: 0.3;
    border-radius: 0.625rem;
    position: absolute;
    left: 1.75rem;
    right: 1.75rem;
    top: -1.125rem;
    background-color: var(--navigation);
    height: 1.75rem;
}

.cSelect {
    width: 1.5625rem;
    height: 1.5625rem;
    border: 0.125rem var(--inputBorder) solid;
    border-radius: 1.25rem;
    cursor: pointer;
    position: absolute;
    left: 0.8125rem;
    top: 1.125rem;
    z-index: 10;
    -webkit-transition: 0.5s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
}

.cSelect.all {
    top: 0.8125rem;
}

.cSelect:hover {
    border: .1rem var(--primaryColor) solid;
    cursor: pointer;
    background-color: var(--offWhite);
    -webkit-transition: 0.5s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
}

.cSelect:active {
    transform: scale(0.9);
    transition: background-color 0.3s;
}

.cSelect.selected {
    background-color: var(--green);
    background-image: url(/assets/images/Icon_Check.png);
    background-size: 45%;
    background-position: center center;
    border: none;
}

.cSelect.selected.disabled {
    background-color: var(--navigation);
    background-image: url(/assets/images/Icon_CheckDisabled.png);
    background-size: 45%;
    background-position: center center;
    border: none;
    cursor: auto;
}

.cSelect.action:before {
    content: '\e937';
}

.cSelect.action {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 1.75rem;
    text-align: center;
    line-height: 3.125rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: none;
    border-radius: 1.875rem;
    width: 3.125rem;
    height: 3.125rem;
    position: absolute;
    right: 0.9375rem;
    top: 1.4375rem;
    left: auto;
    color: var(--white);
    background-color: var(--primaryColor);
    -webkit-box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadow);
    -moz-box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadow);
    box-shadow: 0rem 1.25rem 1.4375rem 0rem var(--shadow);
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.cardStack .cSelect.action {
    top: 1.25rem;
}

.cSelect.action:hover {
    background-color: var(--primaryColor);
    filter: brightness(90%);
    transition: background-color 1s;
    transition: 0.3s;
}

.cSelect.action:active {
    background-color: var(--icon);
    transform: scale(0.9);
    transition: background-color 1s;
    transition: 0.3s;
}

.cSelect.action.check:before {
    content: '\e912';
}

.cSelect.action.check {
    color: var(--white);
    background-color: var(--green);
    -webkit-box-shadow: 0rem 0.375rem 0.625rem 0rem var(--shadow);
    -moz-box-shadow: 0rem 0.375rem 0.625rem 0rem var(--shadow);
    box-shadow: 0rem 0.375rem 0.625rem 0rem var(--shadow);
}

.cSelect.action.minus:before {
    content: '\e943';
}

.cSelect.action.minus {
    background-color: var(--red);
    -webkit-box-shadow: 0rem 0.375rem 0.625rem 0rem var(--shadow);
    -moz-box-shadow: 0rem 0.375rem 0.625rem 0rem var(--shadow);
    box-shadow: 0rem 0.375rem 0.625rem 0rem var(--shadow);
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.cSelect.action.cancel:before {
    content: '\e905';
}

.cSelect.action.cancel {
    background-color: var(--icon);
    -webkit-box-shadow: 0rem 0.375rem 0.625rem 0rem var(--shadow);
    -moz-box-shadow: 0rem 0.375rem 0.625rem 0rem var(--shadow);
    box-shadow: 0rem 0.375rem 0.625rem 0rem var(--shadow);
    font-weight: 600;
    font-size: 2.1875rem;
}

.cSelect.action.minus:hover {
    background-color: var(--red);
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.cSelect.action.minus:active {
    background-color: var(--red);
    box-shadow: 0rem 0.375rem 0.625rem 0rem var(--shadow);
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.cardStack .footer .nameLabel {
    line-height: 2.5rem;
    height: 2.5rem;
}

.cardStack .footer .nameValue {
    line-height: 2.5rem;
    height: 2.5rem;
}

.cardStack .footer a {
    text-decoration: none;
    position: absolute;
    right: 1.25rem;
    height: 2.5rem;
    line-height: 2.5rem;
    font-weight: 600;
}

.cardStack .footer {
    padding-left: 1.25rem;
    position: absolute;
    bottom: 0rem;
    height: 2.5rem;
    line-height: 2.5rem;
    color: var(--stroke);
    width: 100%;
    border-bottom-left-radius: 0.4375rem;
    border-bottom-right-radius: 0.4375rem;
}

.cardArea .footer {
    padding-left: 1.25rem;
    position: relative;
    bottom: 0rem;
    background-color: var(--offWhite);
    height: 2.5rem;
    line-height: 2.5rem;
    color: var(--stroke);
    width: 100%;
    border-bottom-left-radius: 0.4375rem;
    border-bottom-right-radius: 0.4375rem;
}

.cardStack .footer .nameValueGrid.right {
    right: 1.25rem;
}

.cSelect.action:hover {
    background-color: var(--primaryColor);
    -webkit-filter: brightness(75%);
    filter: brightness(75%);
}

.cSelect.action:active {
    background-color: var(--icon);
}

.cSelect.action.minus:active {
    background-color: var(--icon);
}

.item.AVWGW,
.item.VCPEGW,
.item.AWSCPEGW,
.item.GW,
.item.AZCPEGW,
.item.AZSGW,
.item.ZTGW,
.item.ZTNHGW {
    background-image: url(/assets/svgs/No_Gateways.svg);
    background-size: 2.375rem;
    background-position-x: 19.875rem;
}

.item.CL,
.item.ZTCL {
    background-image: url(/assets/svgs/No_Clients.svg);
    background-size: 2.375rem;
    background-position-x: 19.875rem;
}

.item.CL.full,
.item.ZTCL.full {
    background-image: url(/assets/svgs/No_Clients.svg);
    background-size: 6.25rem;
    background-position-x: 5.625rem;
}
